import React from "react";
import "./LoadingBar.css";

const LoadingBar = ({ loadingText="", onDark=false }) => {
  return (
    <div className="loading-bar-container">
      <div className={onDark ? "loading-bar on-dark" : "loading-bar"}></div>
      {loadingText!="" && <p className={onDark ? "loading-text on-dark" : "loading-text"}>{loadingText}</p>}
    </div>
  );
};

export default LoadingBar;
