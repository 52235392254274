import React, { useRef, useState, useEffect, useContext } from 'react';

import { ConfigContext } from '../../context/ConfigContext';
import './ImportSection.css';

const ImportSection = ({
  importType,
  importText,
  acceptedFileFormats
}) => {

  ////////////////// VARIABLES /////////////////////
  const fileInputRef = useRef(null); // Reference to the hidden file input

  const { importedFiles, setImportedFiles } = useContext(ConfigContext);


  ////////////////// METHODS //////////////////////

  // Function to trigger file input dialog
  const handleClick = () => {
    fileInputRef.current.click(); // Trigger the hidden file input
  };

  // Handle file selection from input
  const handleFileChange = (e) => {
    setImportedFiles(Array.from(e.target.files)); // Convert FileList to Array
    console.log("importedFiles", importedFiles);
  };

  // Handle file discard (reset the imported file and file names)
  const handleFileDiscard = (index) => {
    setImportedFiles([]);
    // Reset the file input value to allow re-importing the same file
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };
  


  return (
    <div className="import-section-container">
      <div
        className={"import-section"}
        onClick={handleClick}
      >
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M440-320v-326L336-542l-56-58 200-200 200 200-56 58-104-104v326h-80ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z" /></svg>
        <p className="import-text">{importText}</p>

        {/* Hidden file input */}
        <input
          type="file"
          accept={acceptedFileFormats}
          style={{ display: 'none' }} // Hide the input field
          ref={fileInputRef}
          onChange={handleFileChange}
          multiple={importType === 'multiple'}
        />
      </div>

      {importedFiles.length > 0 && (
        <div className="import-file-info">
          {importedFiles
            .map((file, index) => (
              <div key={index} className="import-file-header">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed">
                  <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
                </svg>
                <span className="import-file-name">{file.name}</span>
                <button
                  className="import-file-discard icon-button"
                  onClick={() => handleFileDiscard(index)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed">
                    <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
                  </svg>
                </button>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default ImportSection;