import React from 'react';
import { NavLink } from 'react-router-dom';
import './ProductsSection.css';

export const products = {
  "charpente-et-structure": {
    id: "charpente-et-structure",
    title: 'Charpente & Structure',
    image: `${process.env.PUBLIC_URL}/products/charpente-structure.webp?v=${new Date().getTime()}`,
    path: 'charpente-et-structure'
  },
  "poutre-technique": {
    id: "poutre-technique",
    title: 'Poutre Technique',
    image: `${process.env.PUBLIC_URL}/products/poutre-technique.webp?v=${new Date().getTime()}`,
    path: 'poutre-technique'
  },
  "mur-bois": {
    id: "mur-bois",
    title: 'Mur Bois',
    image: `${process.env.PUBLIC_URL}/products/mur-bois.webp?v=${new Date().getTime()}`,
    path: 'mur-bois'
  },
  "couverture-bois": {
    id: "couverture-bois",
    title: 'Couverture Bois',
    image: `${process.env.PUBLIC_URL}/products/couverture.webp?v=${new Date().getTime()}`,
    path: 'couverture-bois'
  },
  "coffrage": {
    id: "coffrage",
    title: 'Coffrage',
    image: `${process.env.PUBLIC_URL}/products/coffrage.webp?v=${new Date().getTime()}`,
    path: 'coffrage'
  },
  "bardage": {
    id: "bardage",
    title: 'Bardage',
    image: `${process.env.PUBLIC_URL}/products/bardage.webp?v=${new Date().getTime()}`,
    path: 'bardage'
  },
  "terrasse": {
    id: "terrasse",
    title: 'Terrasse',
    image: `${process.env.PUBLIC_URL}/products/terrasse.webp?v=${new Date().getTime()}`,
    path: 'terrasse'
  },
  "parquet": {
    id: "parquet",
    title: 'Parquet',
    image: `${process.env.PUBLIC_URL}/products/parquet.webp?v=${new Date().getTime()}`,
    path: 'parquet'
  },
  "ecran": {
    id: "ecran",
    title: 'Écran',
    image: `${process.env.PUBLIC_URL}/products/ecran.webp?v=${new Date().getTime()}`,
    path: 'ecran'
  },
  "quincaillerie": {
    id: "quincaillerie",
    title: 'Quincaillerie',
    image: `${process.env.PUBLIC_URL}/products/quincaillerie.webp?v=${new Date().getTime()}`,
    path: 'quincaillerie'
  }
};

const ProductsSection = () => {
  return (
    <>
      <h2 className="product-title">Produits</h2>
      <hr></hr>
      <div className="products-grid">
        {Object.values(products).map((product) => (
          <NavLink 
            to={`/produits/${product.path}`} 
            key={product.id} 
            className="product-card"
          >
            <div className="product-image-container">
              <img src={product.image} alt={product.title} />
            </div>
            <p>{product.title}</p>
          </NavLink>
        ))}
      </div>
    </>
  );
};

export default ProductsSection; 