import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { withAuthenticatorOptions } from '../auth/AuthComponent';

import { ConfigContext } from '../../context/ConfigContext.js';
import useHandleQuantityChange from '../../hooks/useHandleQuantityChange.js';
import useSaveEstimation from '../../hooks/useSaveEstimation.js';
import useDeleteEstimation from '../../hooks/useDeleteEstimation.js';
import useUpdateEstimationName from '../../hooks/useUpdateEstimationName.js';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import Icon from '../../components/icons/Icon.jsx';
import QuantityControl from '../../components/common/QuantityControl.js';
import Modal from '../../components/layout/Modal.js';
import './EstimatePage.css';

import LoadingIconButton from '../../components/common/LoadingIconButton.jsx';
import LoadingButton from '../../components/common/LoadingButton.jsx';

const EstimatePage = () => {
    const navigate = useNavigate();
    const { userRole, currentEstimation, setCurrentEstimation } = useContext(ConfigContext);
    const [isEditing, setIsEditing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [showSaveText, setShowSaveText] = useState(false);
    const [productToPreview, setProductToPreview] = useState(null);

    const { handleQuantityChange, handleQuantityValueChange } = useHandleQuantityChange();
    const { saveEstimation } = useSaveEstimation();
    const { deleteEstimation } = useDeleteEstimation();
    const { updateEstimationName } = useUpdateEstimationName();

    const [editedEstimateTitle, setEditedEstimateTitle] = useState('');

    // Redirect to /my-account if currentEstimation is not available
    useEffect(() => {
        if (userRole !== 'user') {
            navigate('/mon-espace-client');
        }
    }, [userRole, navigate]);

    const handleSaveEstimate = async () => {
        setShowSaveText(false);
        await saveEstimation(currentEstimation, setIsSaving).then();
        setShowSaveText(true);
        navigate("/mon-espace-client");
    };

    // Start editing the estimate title
    const startEditingEstimate = (currentTitle) => {
        setEditedEstimateTitle(currentTitle);
        setIsEditing(true);
    };

    // Handle title change
    const handleEstimateTitleChange = (e) => {
        setEditedEstimateTitle(e.target.value);
    };

    // Save the new title
    const saveEstimateTitle = async (item) => {
        setIsEditing(false); // Assurez-vous de quitter le mode d'édition
        setCurrentEstimation({...currentEstimation, estimationName: editedEstimateTitle});
        if (editedEstimateTitle.trim() && editedEstimateTitle !== item.name) {
            console.log("New title:", editedEstimateTitle);
            // Logique pour mettre à jour le titre du devis
            await updateEstimationName(editedEstimateTitle); // Implémentez cette fonction selon vos besoins
        }
    };

    // Handle Enter key press
    const handleEstimateKeyPress = (e, item) => {
        if (e.key === 'Enter') {
            saveEstimateTitle(item);
        }
    };

    // Handle blur (click outside)
    const handleEstimateBlur = (item) => {
        saveEstimateTitle(item);
    };

    const calculateTotal = () => {
        return currentEstimation.purchasePlan.reduce((total, item) => {
            return total + (item.quantity || 0) * (item.price || 0);
        }, 0);
    };

    const handleAddProducts = () => {
        navigate('/produits');
    };

    const handlePreview = (product) => {
        setProductToPreview(product);
        setShowPreview(true);
    }

    const handleDelete = async () => {
        if (currentEstimation.estimationId === 'currentEstimationId') {
            setCurrentEstimation({
                estimationId: "currentEstimationId", 
                estimationName: `Devis du ${new Date().toISOString().split('T')[0]}`, 
                purchasePlan: [], 
                lastModified: `${new Date().toISOString().split('T')[0]}`
              });
            navigate(-1);
        } else {
            await deleteEstimation(currentEstimation.estimationId, setIsDeleting);
            navigate('/mon-espace-client');
        }
    }

    const handleEditPurchasePlan = (product, operation) => {
        handleQuantityChange(product, operation);
        setShowSaveText(false);
    };
    
    const handleEditQuantity = (product, quantity) => {
        handleQuantityValueChange(product, quantity);
        setShowSaveText(false);
    };

    const handleCreateNewEstimation = () => {
        setCurrentEstimation({
            estimationId: "currentEstimationId",
            estimationName: `Devis du ${new Date().toISOString()}`,
            purchasePlan: [],
            lastModified: `${new Date().toISOString()}`
        });
        navigate("/produits");
    }

    return (
        // <div className="EstimatePage bg-image" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/abstract-wooden-bg-03.svg)` }}>
        <div className="EstimatePage bg-image" >
            <Header activePage="mon-espace-client" helmetTitle={`Drouaire - Devis ${currentEstimation.id || ''}`} />
            <main>
                <section className="estimate-section">
                    <div className="estimate-header">
                        <div className="my-account-estimate-link">

                            {/* <div
                                className='my-account-estimate-back-link'
                                onClick={() => navigate(-1)}
                                >
                                <Icon name="arrow_back" />
                            </div> */}
                            <a href="/mon-espace-client" >Mes devis</a><span> / {currentEstimation.estimationName}</span>
                        </div>
                        <LoadingIconButton 
                            isLoading={isDeleting}
                            icon="delete"
                            onClick={() => handleDelete()}
                        />
                    </div>
                    <div className="estimate-title-header">

                        {isEditing ? (
                            <div className="editable-estimate-title-container">
                                <input
                                    type="text"
                                    value={editedEstimateTitle}
                                    className='editable-estimate-title'
                                    onChange={handleEstimateTitleChange}
                                    onBlur={() => handleEstimateBlur(currentEstimation)} // Save on blur
                                    onKeyPress={(e) => handleEstimateKeyPress(e, currentEstimation)} // Save on Enter
                                    autoFocus
                                />
                            </div>
                        ) : (
                            <div className='editable-estimate-title-container-with-button'>
                                <button
                                    className='editable-estimate-title'
                                    onClick={() => startEditingEstimate(currentEstimation.estimationName)} // Enable editing on double-click
                                    >
                                    <p>{currentEstimation.estimationName}</p>
                                </button>
                                <div className="icon-button" onClick={() => startEditingEstimate(currentEstimation.estimationName)}>
                                    <Icon name="edit" />
                                </div>
                            </div>
                        )}
                        
                    </div>
                    <p className="estimate-content-description">Vous pouvez modifier le contenu de votre devis en cliquant sur les lignes des articles.</p>
                    <div className="estimate-content">
                        <div className="estimate-table-container">
                            {currentEstimation.purchasePlan.map((product) => (
                                <div className="estimate-product-container" onClick={() => handlePreview(product)}>
                                    <div className="estimate-product-header">
                                        <p className="secondary-text-color">{product.quantity}x</p>
                                        <strong>{product.denomination}</strong>
                                    </div>
                                    <div className="estimate-quantity-price">
                                        <p className="secondary-text-color">{product.productSection}x{product.productLength}</p>
                                        <p>{product.price * product.quantity} €</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="estimate-footer">
                            <div className="estimate-total">
                                <div className="estimate-total-label">TOTAL</div>
                                <div className="estimate-total-amount">{calculateTotal()} €</div>
                            </div>
                            <div className="estimate-actions">

                                <button className="create-new-estimate-btn secondary-button" onClick={handleCreateNewEstimation}>
                                    Créer un nouveau devis
                                </button>
                                <button className="secondary-button" onClick={handleAddProducts}>
                                    Ajouter des articles
                                </button>
                                <LoadingButton
                                    isLoading={isSaving}
                                    text="Enregistrer le devis"
                                    onClick={() => handleSaveEstimate()}
                                    className='primary-button'
                                />
                            </div>
                            {showSaveText && <div className="estimate-save-text">Enregistré.</div>}
                        </div>
                    </div>
                </section>
                <div className="estimate-item-modal-overlay">
                    {showPreview && (
                        <Modal onClose={() => setShowPreview(false)}>
                            <div className="estimate-item-modal">
                                <div className="estimate-item-modal-content">
                                    <div className="estimate-product-header">
                                        <strong>{productToPreview.denomination}</strong>
                                    </div>
                                    <div className="estimate-quantity-price">
                                        <p>{productToPreview.productSection}x{productToPreview.productLength}</p>
                                        <p>{productToPreview.price * productToPreview.quantity} €</p>
                                    </div>
                                </div>
                                <QuantityControl
                                    product={productToPreview}
                                    currentEstimation={currentEstimation}
                                    handleQuantityChange={handleEditPurchasePlan}
                                    handleQuantityValueChange={handleEditQuantity}
                                />
                            </div>
                        </Modal>
                    )}
                </div>
            </main>
            <Footer />
        </div>
    );
};

  
export default withAuthenticator(EstimatePage,  withAuthenticatorOptions);