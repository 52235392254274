import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaMapMarkerAlt, FaCalendarAlt, FaRulerCombined, FaUsers, FaBox, FaPencilAlt, FaTrashAlt } from 'react-icons/fa';

import { ConfigContext } from '../../context/ConfigContext';
import useDeleteReference from '../../hooks/useDeleteReference';
import useGetReferences from '../../hooks/useGetReferences';
import './ReferencesSection.css';
import './ReferencesSection.css';
import Icon from '../icons/Icon.jsx';
import LoadingIconButton from '../common/LoadingIconButton.jsx';

const ReferenceInfo = ({ icon: Icon, label, content }) => {
  if (!content) return null;

  return (
    <div className="reference-info">
      <div className="reference-info-inline">
        <div className="reference-info-label">{label}</div>
      </div>
      <div className="reference-info-text">
        <div className="reference-info-content">{content}</div>
      </div>
    </div>
  );
};

const ReferenceItem = ({ key, referenceItem, isEditable = false }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const { deleteReference } = useDeleteReference();
  const navigate = useNavigate();

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < referenceImages.length - 1 ? prevIndex + 1 : 0
    );
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : referenceImages.length - 1
    );
  };
  const handleEditReference = () => {
    navigate('/creation-de-reference', {
      state: {
        isEditing: true,
        oldData: referenceItem,
      },
    });
  }
  // Determine background image or video
  const getReferenceImages = () => {
    if (!referenceItem?.photos || !Array.isArray(referenceItem.photos)) {
      return null; // Return null if photos is not defined or not an array
    }

    // Map over the photos array to generate URLs
    const referenceImages = referenceItem.photos.map((photo) => {
      if (photo instanceof File) {
        // If it's a File object, generate an object URL
        return URL.createObjectURL(photo);
      } else if (typeof photo === 'string') {
        // If it's a string, assume it's already a URL
        return photo;
      } else {
        // Handle unsupported types (optional)
        console.warn("Unsupported photo type:", photo);
        return null;
      }
    });

    // Filter out any null values in case of unsupported types
    return referenceImages.filter(Boolean);
  };

  const referenceImages = getReferenceImages();

  const handleDeleteReference = async (referenceItem) => {
    await deleteReference(referenceItem, setIsDeleting);
  }

  return (
    <div className="reference-item" id={key}>
      <div className="reference-title-button-container">
        <div className="reference-title-button-sub-container">
          {isEditable && (
            <button className="reference-title-edit-button icon-button" onClick={() => handleEditReference(referenceItem)}>
              <Icon name="edit" />
            </button>
          )}
          {isEditable && (
            <LoadingIconButton
              icon="delete"
              isLoading={isDeleting}
              onClick={() => handleDeleteReference(referenceItem)}
              className="reference-title-delete-button"
            />
          )}
        </div>
      </div>
      
      <div className="reference-header">
        <div className='reference-title'>
          <h2>{referenceItem.referenceName || "-"}</h2>
        </div>
      </div>

      <div className="reference-content">
        <div className="reference-left-column">
          <div className='reference-background-container'>
            {referenceImages?.length > 0 && (
              <>
                <img
                  src={referenceImages[currentIndex]}
                  alt={`Photo du projet ${referenceItem.referenceName}`}
                  className="reference-background-image"
                />
                {referenceImages?.length > 1 && (
                  <button className="chevron right" onClick={handleNext}>
                    <Icon name="keyboard_arrow_up" />
                  </button>
                )}
                {referenceImages?.length > 1 && (
                  <button className="chevron left" onClick={handlePrevious}>
                    <Icon name="keyboard_arrow_down" />
                  </button>
                )}
              </>
            )}
          </div>
          
          {referenceItem.description && (
            <div className="reference-info reference-description">
              <div className="reference-info-inline">
                <div className="reference-info-label">Descriptif</div>
              </div>
              <div className="reference-info-text">
                <div className="reference-info-content">{referenceItem.description}</div>
              </div>
            </div>
          )}
        </div>
        
        <div className='reference-details-container'>
          <div className="reference-details">
            <ReferenceInfo icon={FaCalendarAlt} label="Période" content={referenceItem.period} />
            <ReferenceInfo icon={FaRulerCombined} label="Surface" content={referenceItem.surface} />
            <ReferenceInfo icon={FaUsers} label="Intervenants" content={referenceItem.stakeHolders} />
            <ReferenceInfo icon={FaBox} label="Type de produits fournis" content={referenceItem.products} />
          </div>
        </div>
      </div>
    </div>
  );
};

const ReferenceCard = ({ referenceItem, onClick }) => {
  // Récupérer la première image pour la carte
  const getFirstImage = () => {
    if (!referenceItem?.photos || !Array.isArray(referenceItem.photos) || referenceItem.photos.length === 0) {
      return null;
    }

    const photo = referenceItem.photos[0];
    if (photo instanceof File) {
      return URL.createObjectURL(photo);
    } else if (typeof photo === 'string') {
      return photo;
    }
    return null;
  };

  const imageUrl = getFirstImage();

  return (
    <div className="reference-card" onClick={onClick}>
      {imageUrl && (
        <img 
          src={imageUrl} 
          alt={`Photo du projet ${referenceItem.referenceName}`} 
          className="reference-card-image"
        />
      )}
      <div className="reference-card-overlay">
        <h3 className="reference-card-title">{referenceItem.referenceName || "-"}</h3>
      </div>
    </div>
  );
};

const ReferencesSection = ({ isEditable = false, onReferencesLoaded }) => {
  const { references } = useContext(ConfigContext);
  const { getReferences } = useGetReferences();
  const [selectedReference, setSelectedReference] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getReferences()
      .then(data => {
        if (onReferencesLoaded) {
          onReferencesLoaded();
        }
      });
  }, []);

  const handleReferenceClick = (reference) => {
    setSelectedReference(reference);
  };

  const handleBackToList = () => {
    setSelectedReference(null);
  };

  return (
    <section>
      {selectedReference ? (
        <div >
          <button 
            onClick={handleBackToList}
            className='secondary-button reference-back-button'
          >
            Retour aux références
          </button>
          <ReferenceItem 
            referenceItem={selectedReference} 
            isEditable={isEditable} 
          />
        </div>
      ) : (
        <div className="references-section">
          {references.map((reference, index) => (
            <ReferenceCard
              key={index}
              referenceItem={reference}
              onClick={() => handleReferenceClick(reference)}
            />
          ))}
        </div>
      )}
    </section>
  );
};

export default ReferencesSection;
export { ReferenceItem };
