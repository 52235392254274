import { useContext } from 'react';

import { ConfigContext } from '../context/ConfigContext.js';

const useGetNews = () => {
  const { setNews } = useContext(ConfigContext);
  
  // Function to fetch news from the backend
  const getNews = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;

      const response = await fetch(`${apiUrl}/api/news/getNews`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setNews(data.newsList[0]);
      return data.newsList[0];
      
    } catch (error) {
      console.error('Error during news retrieval :', error);
    }
  };

  return {
    getNews
  };

};

export default useGetNews;