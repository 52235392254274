import React from 'react';
import './Title.css';

const Title = ({ title }) => {
    return (
        <div className="title-with-hr-container">
            <h2>{title}</h2>
            <hr />
        </div>
    );
};

export default Title;