import React from 'react';
import { BrowserRouter  as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async'; // Importer HelmetProvider



import { Amplify } from 'aws-amplify';
import awsConfig from './aws-exports';

import ScrollToTop from './hooks/ScrollToTop';

import { ConfigProvider } from './context/ConfigContext';
import HomePage from './pages/HomePage/HomePage';
import ProductPage from './pages/ProductPage/ProductPage';
import ReferencePage from './pages/ReferencePage/ReferencePage';
import ContactPage from './pages/ContactPage/ContactPage';
import MyAccountPage from './pages/AccountPage/MyAccountPage';
import LegalNoticePage from './pages/LegalPage/LegalNoticePage';
import PrivacyPolicyPage from './pages/LegalPage/PrivacyPolicyPage';
import EntreprisePage from './pages/EntreprisePage/EntreprisePage';
import ValeursRsePage from './pages/EntreprisePage/ValeursRsePage';
import ProductDetailPage from './pages/ProductPage/ProductDetailPage';
import NewsCreationPage from './pages/AccountPage/NewsCreationPage';
import ReferenceCreationPage from './pages/AccountPage/ReferenceCreationPage';
import EstimatePage from './pages/AccountPage/EstimatePage';
import CommercialEstimatePage from './pages/AccountPage/CommercialEstimatePage';
import NotFoundPage from './pages/NotFoundPage';
import HistoirePage from './pages/EntreprisePage/HistoirePage';
import './main.css';
import './i18n';
import './fonts/fonts.css';

// Apply the custom configuration
Amplify.configure(awsConfig);

// POUR L'I18N (MULTI LANGUES)
// import { useEffect } from "react";
// import { useLocation } from "react-router-dom";
// import { useTranslation } from "react-i18next";
// import { startTransition } from 'react';

function App() {
  // const { i18n } = useTranslation();

  // useEffect(() => {
  //   const lang = "en"
  //   if (["fr", "en", "ro"].includes(lang)) {
  //     startTransition(() => {
  //       i18n.changeLanguage(lang);
  //     });
  //   }
  // }, [location, i18n]);

  return (
    <HelmetProvider>
      <ConfigProvider>
        <Router>
          <ScrollToTop />
          <div className="App">
            <Routes>
              {['/', '/fr', '/en', '/ro'].map((path) => (
                <Route key={path} path={path} element={<HomePage />} />
              ))}
              {['/produits', '/fr/produits', '/en/products', '/ro/produsele-noastre'].map((path) => (
                <Route key={path} path={path} element={<ProductPage />} />
              ))}
              {['/l-entreprise', '/fr/l-entreprise', '/en/company', '/ro/companie'].map((path) => (
                <Route key={path} path={path} element={<EntreprisePage />} />
              ))}
              <Route path="/realisations" element={<ReferencePage />} />
              {/* <Route path="/valeurs-et-rse" element={<ValeursRsePage />} /> */}
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/mentions-legales" element={<LegalNoticePage />} />
              <Route path="/politique-de-confidentialite" element={<PrivacyPolicyPage />} />
              
              <Route path="/mon-espace-client" element={<MyAccountPage />} />
              <Route path="/mon-espace-client/devis" element={<EstimatePage />} />
              <Route path="/mon-espace-client/devis-commercial" element={<CommercialEstimatePage />} />
              <Route path="/creation-de-reference" element={<ReferenceCreationPage />} />
              <Route path="/modification-d-actualite" element={<NewsCreationPage />} />

              <Route path="/produits/charpente-et-structure" element={
                <ProductDetailPage 
                  pageCategory="charpente-et-structure" 
                  quote="“95% de nos bois de charpente et de structure sont issus de forêts françaises.”"
                />}/>
              <Route path="/produits/poutre-technique" element={
                <ProductDetailPage 
                  pageCategory="poutre-technique" 
                  quote="“Un large éventail de dimensions pour répondre à vos besoins en structure, en plancher, bardage, isolation ou aménagement intérieur.“"
                />}/>
              <Route path="/produits/mur-bois" element={
                <ProductDetailPage 
                  pageCategory="mur-bois" 
                  quote="“Un choix en dalles, panneaux, contreventements et contreplaqués et OSB répondant à tous vos besoins.”"
                />}/>
              <Route path="/produits/couverture-bois" element={
                <ProductDetailPage 
                  pageCategory="couverture-bois" 
                  quote="“Une gamme étendue de produits en Pin, Douglas ou Epicéa adaptés à vos chantiers de couverture.”"
                />}/>
              <Route path="/produits/coffrage" element={
                <ProductDetailPage 
                  pageCategory="coffrage" 
                  quote="“Des bois de coffrage sélectionnés pour leur résistance mécanique.”"
                />}/>
              <Route path="/produits/bardage" element={
                <ProductDetailPage 
                  pageCategory="bardage" 
                  quote="“Qu’ils soient bruts ou peints, nous privilégions des clins bois certifiés PEFC. Faites-vous accompagner par un expert Drouaire pour vos besoins standards et spécifiques (profil, couleur...).”"
                />}/>
              <Route path="/produits/terrasse" element={
                <ProductDetailPage 
                  pageCategory="terrasse" 
                  quote="“Traités ou naturellement classe 4, nos bois de la gamme terrasse répondent aux exigences en matière de qualité, de durabilité et de prix.“" 
                />}/>
              <Route path="/produits/parquet" element={
                <ProductDetailPage 
                  pageCategory="parquet" 
                  quote="“Laissez-vous guider, pas à pas, parmi nos gammes de parquets massifs, contrecollés ou stratifiés, leur palette de teintes et de finitions.“"
                />}/>
              <Route path="/produits/ecran" element={
                <ProductDetailPage 
                  pageCategory="ecran" 
                  quote=""
                />}/>
              <Route path="/produits/quincaillerie" element={
                <ProductDetailPage 
                  pageCategory="quincaillerie" 
                  quote="“Drouaire, c’est aussi une sélection d’outils, de produits de quincaillerie et de finition parmi les meilleures marques.“"
                />}/>
              <Route path="/histoire" element={<HistoirePage />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </div>
        </Router>
      </ConfigProvider>
    </HelmetProvider>
  );
}

export default App;