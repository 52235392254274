  import React, { useState, useEffect  } from 'react';
  import { NavLink, Link } from 'react-router-dom';
  import { Helmet } from 'react-helmet-async';
  import '../../main.css';
  import './Header.css';


  
  const Header = ({ activePage, helmetTitle, preloadImages=[] }) => { // Updated: Destructure activePage
    
    // State pour le menu mobile
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [lastScrollTop, setLastScrollTop] = useState(0); // Pour mémoriser la position du scroll
    const [isHeaderVisible, setHeaderVisible] = useState(true); // Pour contrôler la visibilité du header

    // Toggle le menu mobile
    const toggleMenu = () => {
      setMenuOpen(!isMenuOpen);
    };

    // Fermer le menu au clic sur un lien
    const closeMenu = () => {
      setMenuOpen(false);
    };

    // Effet pour gérer le scroll et cacher le header
    useEffect(() => {
      const handleScroll = () => {
        const currentScroll = window.pageYOffset || document.documentElement.scrollTop;

        if (currentScroll > lastScrollTop) {
          // On défile vers le bas, cacher le header
          setHeaderVisible(false);
        } else {
          // On défile vers le haut, montrer le header
          setHeaderVisible(true);
        }

        setLastScrollTop(currentScroll <= 0 ? 0 : currentScroll); // Pour éviter les valeurs négatives
      };

      window.addEventListener('scroll', handleScroll);
      
      // Nettoyage de l'événement au démontage du composant
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [lastScrollTop]);
  
    return (
      
      <header className={`header ${isHeaderVisible ? 'visible' : 'hidden'}`}>
      <Helmet>
        <title>{helmetTitle}</title>
        <meta name="description" content="Drouaire, négoce bois, panneau, lambris" />
        <link rel="icon" type="image/png" href="/helmet-icon.png" sizes="32x32" />
        {preloadImages.map((imageSrc) => (
          <link rel="preload" href={imageSrc} as="image" key={imageSrc} />
        ))}
      </Helmet>
        <div className="header-container dark">
          {/* Logo Container */}
          <div className="logo-container">
            <NavLink to="/">
              <img src={`${process.env.PUBLIC_URL}/logo-drouaire/logo-drouaire-Longue-Uni-Ivoire.svg?v=${new Date().getTime()}`} alt="Drouaire Logo" className="logo" />
            </NavLink>
          </div>
    
          {/* Menu Container for Larger Screens */}
          <nav className="menu-container">
            <ul className="menu">
              <NavLink to="/">
              <li className={activePage === 'accueil' ? 'active-menu-item' : ''}>
                  Accueil
                </li>
              </NavLink>
              <NavLink to="/produits">
                <li className={activePage === 'produits' ? 'active-menu-item' : ''}>
                  Produits
                </li>
              </NavLink>
              <NavLink to="/realisations">
                <li className={activePage === 'realisations' ? 'active-menu-item' : ''}>
                  Réalisations
                </li>
              </NavLink>
              <NavLink to="/l-entreprise">
                <li className={activePage === 'l-entreprise' ? 'active-menu-item' : ''}>
                  L'entreprise
                </li>
              </NavLink>
              {/* <NavLink to="/valeurs-et-rse">
                <li className={activePage === 'valeurs-et-rse' ? 'active-menu-item' : ''}>
                  Valeurs et RSE
                </li>
              </NavLink> */}
              <NavLink to="/contact">
                <li className={activePage === 'contact' ? 'active-menu-item' : ''}>
                  Contact
                </li>
              </NavLink>
              <NavLink to="/mon-espace-client">
                <li className={activePage === 'mon-espace-client' ? 'active-menu-item' : ''}>
                  Espace client
                </li>
              </NavLink>
            </ul>
          </nav>
    
    
          {/* Updated: Hamburger Icon for Mobile */}
          <div className="hamburger-menu" onClick={toggleMenu}>
            <div className="hamburger-icon">
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed">
                <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z"/>
              </svg>
            </div>
          </div>
    
          {/* Updated: Mobile Slide-in Menu */}
          <div className={`mobile-menu ${isMenuOpen ? 'open' : ''}`}>
            <div className='mobile-menu-content dark'>
              <ul>
                <li onClick={closeMenu} className={activePage === 'accueil' ? 'active-mobile-menu-item' : ''}>
                  <NavLink to="/">Accueil</NavLink>
                </li>
                <li onClick={closeMenu} className={activePage === 'produits' ? 'active-mobile-menu-item' : ''}>
                  <NavLink to="/produits">Produits</NavLink>
                </li>
                <li onClick={closeMenu} className={activePage === 'realisations' ? 'active-mobile-menu-item' : ''}>
                  <NavLink to="/realisations">Réalisations</NavLink>
                </li>
                <li onClick={closeMenu} className={activePage === 'l-entreprise' ? 'active-mobile-menu-item' : ''}>
                  <NavLink to="/l-entreprise">L'entreprise</NavLink>
                </li>
                {/* <li onClick={closeMenu} className={activePage === 'valeurs-et-rse' ? 'active-mobile-menu-item' : ''}>
                  <NavLink to="/valeurs-et-rse">Valeurs et RSE</NavLink>
                </li> */}
                <li onClick={closeMenu} className={activePage === 'contact' ? 'active-mobile-menu-item' : ''}>
                  <NavLink to="/contact">Contact</NavLink>
                </li>
                <li onClick={closeMenu} className={activePage === 'mon-espace-client' ? 'active-mobile-menu-item' : ''}>
                  <NavLink to="/mon-espace-client">Mon espace client</NavLink>
                </li>
              </ul>
            </div>
          </div>
    
          {/* Updated: Overlay for Mobile Menu */}
          {isMenuOpen && <div className="menu-overlay" onClick={closeMenu}></div>}
        </div>
      </header>
    );
  };
  
  export default Header;