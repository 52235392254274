import { useContext } from 'react';

import { ConfigContext } from '../context/ConfigContext.js';

const useDeleteReference = () => {
  const { idToken, accessToken, setReferences } = useContext(ConfigContext);
  
  // Function to fetch reference from the backend
  const deleteReference = async (reference, setIsDeleting) => {
    try {
      setIsDeleting(true);
      const apiUrl = process.env.REACT_APP_API_URL;

      const response = await fetch(`${apiUrl}/api/reference/deleteReference?referenceId=${reference.referenceId}`, {
        method: 'DELETE',
        headers: {
          'X-ID-Token': idToken,
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      // Update the local references state excluding the deleted reference
      setReferences((prevReferences) =>
        prevReferences.filter((ref) => ref.referenceId !== reference.referenceId)
    );
      
    } catch (error) {
      console.error('Error during estimation list retrieval :', error);
    }
    setIsDeleting(false);
  };

  return {
    deleteReference
  };

};

export default useDeleteReference;