import React, { useContext, useEffect, useState, useRef, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import Icon from '../icons/Icon.jsx';

import { ConfigContext } from '../../context/ConfigContext';
import useGetNews from '../../hooks/useGetNews';
import './NewsDisplay.css';
import LoadingIndicator from './LoadingIndicator.js';
import './LoadingIndicator.css';

const NewsDisplay = ({ newsItem: propNewsItem = null }) => {
  const { news } = useContext(ConfigContext);
  const [loading, setLoading] = useState(true);
  const [newsItem, setNewsItem] = useState(propNewsItem);
  const { getNews } = useGetNews();
  const hasFetched = useRef(false);

  const getMediaElement = () => {
    if (newsItem?.videoUrl) {
      return (
        <video
          className="media-element"
          autoPlay
          muted
          loop
          playsInline
        >
          <source src={newsItem.videoUrl} type="video/mp4" />
        </video>
      );
    } else if (newsItem?.mediaFile || newsItem?.pictureUrl) {
      const imageUrl = newsItem?.mediaFile
        ? URL.createObjectURL(newsItem.mediaFile)
        : newsItem.pictureUrl;
      return (
        <div
          className="media-element"
          style={{ backgroundImage: `url(${imageUrl})` }}
        />
      );
    }
    return null;
  };

  // 1. Memoization des médias pour éviter des re-renders inutiles
  const mediaElement = useMemo(() => getMediaElement(), [
    newsItem?.mediaFile,
    newsItem?.pictureUrl,
    newsItem?.videoUrl
  ]);

  useEffect(() => {
    const fetchNews = async () => {
      if (!propNewsItem && !hasFetched.current) {
        hasFetched.current = true; // Mark as fetched to prevent loops
        try {
          const fetchedNews = await getNews(); // Await the result of getNews
          setNewsItem(fetchedNews); // Use the fetched news directly
        } catch (error) {
          console.error("Error fetching news:", error);
          setNewsItem(news);
        } finally {
          setTimeout(() => setLoading(false), 500);
        }
      } else if (propNewsItem) {
        setNewsItem(propNewsItem); // Use the provided prop if available
        setLoading(false);
      }
      adjustHeight();
    };

    fetchNews();
  }, [propNewsItem, getNews]);

  // 2. Gestion de la libération des Object URLs
  useEffect(() => {
    let objectUrl;
    if (newsItem?.mediaFile) {
      objectUrl = URL.createObjectURL(newsItem.mediaFile);
    }

    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [newsItem?.mediaFile]);


  const handleScrollDown = () => {
    window.scrollBy({
      top: window.innerHeight, // Scroll down by the height of the viewport
      behavior: 'smooth' // Smooth scrolling
    });
  };

  // Ajuste la hauteur au chargement de la page et lors du redimensionnement
  function adjustHeight() {
    const div = document.querySelector('.news-item');
    const video = document.querySelector('video');
    if (div) { // Vérifiez si div n'est pas null
      const marginTop = 4 * parseFloat(getComputedStyle(document.documentElement).fontSize);
      div.style.height = `${window.innerHeight - marginTop}px`;
      if (video) {
        video.style.height = `${window.innerHeight - marginTop}px`;
      }
    }
  }

  window.addEventListener('load', adjustHeight);
  window.addEventListener('resize', adjustHeight);

  return (
    <div className="news-display">
      {loading && <LoadingIndicator />}
      {!loading && (
        <div className="news-item">
          {getMediaElement()}
          <div className="news-overlay">
            <div className="news-display-content">
              <p className="news-display-quote">ACTUALITÉ</p>
              <h3 className="news-display-title">{newsItem?.title}</h3>
              <p className="news-display-description">{newsItem?.description}</p>
              <div className="news-display-link-container">
                <a
                  className="news-display-link"
                  href={newsItem.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {newsItem?.linkText}
                </a>
              </div>
              <div className="news-display-arrow-container">
                <button className="icon-button" onClick={handleScrollDown}>
                  <Icon name="keyboard_arrow_down" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewsDisplay; 