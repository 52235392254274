import { useEffect, useContext } from 'react';

import { ConfigContext } from '../context/ConfigContext.js';

const useGetProductListByCategory = (setLoading) => {
  const { activeCategory, setProductList } = useContext(ConfigContext);
  
  // Function to fetch chat history from the backend
  const getProductListByCategory = async () => {
    
    try {
      const apiUrl = process.env.REACT_APP_API_URL;

      const response = await fetch(`${apiUrl}/api/product/getProductListByCategory?category=${activeCategory}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setProductList(data.productList); // Mettre à jour l'état avec les titres des chats
      setLoading(false);
      
    } catch (error) {
      console.error('Error during product list retrieval :', error);
    }
  };

  // Function to fetch chat history when the hook is used or when refreshChatHistory changes
  useEffect(() => {
    getProductListByCategory();
  }, [activeCategory]);

  return;
};

export default useGetProductListByCategory;