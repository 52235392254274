import { useContext } from 'react';

import { ConfigContext } from '../context/ConfigContext.js';

const useSaveNews = () => {
  const { idToken, accessToken, news } = useContext(ConfigContext);
  
  // Function to fetch chat history from the backend
  const saveNews = async (title, description, link, linkText, mediaUrl, setIsLoading) => {
    
    try {

      // Identify media type based on file extension
      let pictureUrl = "";
      let videoUrl = "";

      if (mediaUrl) {
        const extension = mediaUrl.split('.').pop().toLowerCase();
        const imageExtensions = ['webp', 'jpg', 'jpeg', 'png', 'gif'];
        const videoExtensions = ['mp4', 'avi', 'mov', 'mkv', 'webm'];

        if (imageExtensions.includes(extension)) {
          pictureUrl = mediaUrl;
        } else if (videoExtensions.includes(extension)) {
          videoUrl = mediaUrl;
        } else {
          throw new Error('Unsupported media type. Please use a valid image or video URL.');
        }
      }

      const apiUrl = process.env.REACT_APP_API_URL;

      const response = await fetch(`${apiUrl}/api/news/saveNews`, {
        method: 'POST',
        headers: {
          'X-ID-Token': idToken,
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          newsId: news.newsId, 
          date: new Date().toISOString().split('T')[0],
          title: title,
          description: description,
          link: link,
          linkText: linkText,
          pictureUrl: pictureUrl,
          videoUrl: videoUrl
         }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      console.log('News saved');
      
    } catch (error) {
      console.error('Error during news list retrieval :', error);
    }
    setIsLoading(false);
  };

  return { saveNews };
};

export default useSaveNews;