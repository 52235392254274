import React from "react";
import "./LoadingSpinner.css";

const LoadingSpinner = ({ onPrimary=false, size="3rem"}) => {
  return (
    <div className="loading-spinner">
      <div className={onPrimary ? "spinner on-primary" : "spinner"	} style={{width: size, height: size}}></div>
    </div>
  );
};

export default LoadingSpinner;
