import React, { useState, useEffect } from 'react';
import Icon from '../icons/Icon.jsx';
import './QuantityControl.css';

const QuantityControl = ({ product, currentEstimation, handleQuantityChange, handleQuantityValueChange }) => {
  const [quantity, setQuantity] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState('0');

  useEffect(() => {
    const existingProduct = currentEstimation.purchasePlan.find(
      (item) => item.productId === product.productId
    );
    const newQuantity = existingProduct ? existingProduct.quantity : 0;
    setQuantity(newQuantity);
    setInputValue(newQuantity.toString());
  }, [currentEstimation, product.productId]);

  const increment = () => {
    handleQuantityChange(product, 'increment');
  };

  const decrement = () => {
    if (quantity > 0) {
      handleQuantityChange(product, 'decrement');
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
  
    // Autoriser la chaîne vide temporairement
    if (value === '') {
      setInputValue('');
      return;
    }
  
    // Convertir en nombre et vérifier qu'il est valide
    const numValue = parseInt(value, 10);
    if (!isNaN(numValue) && numValue >= 0) {
      setInputValue(value);
    }
  };

  const handleInputBlur = () => {
    setIsEditing(false);
    
    // Si la chaîne est vide, définir la valeur à 0
    const newQuantity = parseInt(inputValue, 10) || 0;
  
    // Si la quantité a changé, mettre à jour
    if (newQuantity !== quantity) {
      handleQuantityValueChange(product, newQuantity);
    }
  
    // Synchroniser l'affichage avec une valeur valide
    setInputValue(newQuantity.toString());
  };

  const handleInputFocus = (e) => {
    setIsEditing(true);
    e.target.select(); // Sélectionne tout le texte
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  };

  return (
    <div className="quantity-control-row">
      <button className="quantity-control-btn icon-button" onClick={decrement}>
        <Icon name="remove" />
      </button>
      {isEditing ? (
        <input
          type="number"
          className="quantity-control-input"
          value={inputValue}
          min="0"
          step="1"
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onFocus={handleInputFocus}
          onKeyPress={handleKeyPress}
          autoFocus
        />
      ) : (
        <span 
          className="quantity-control-value"
          onClick={() => setIsEditing(true)}
        >
          {quantity}
        </span>
      )}
      <button className="quantity-control-btn icon-button" onClick={increment}>
        <Icon name="add" />
      </button>
    </div>
  );
};

export default QuantityControl;