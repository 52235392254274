import React from 'react';
import './GoogleMap.css';

const GoogleMap = () => {
    return (
        <div className="google-map">
            <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5248.962148623545!2d2.5704524759622718!3d48.868105000023434!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e611c1bb1b9c99%3A0xbbfb922b1040807d!2sDrouaire%20Bois%20Panneaux%20Lambris!5e0!3m2!1sfr!2sfr!4v1735416792529!5m2!1sfr!2sfr"
            ></iframe>
        </div>
    );
};

export default GoogleMap;