import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import ProductsSection from '../../components/sections/ProductsSection';
import '../../main.css';
import './ProductPage.css';
import { NavLink } from 'react-router-dom';
import Title from '../../components/layout/Title';

const ProductPage = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const elementId = location.hash.replace('#', '');
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    // <div className="ProductPage bg-image" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/abstract-wooden-bg-03.svg)` }}>
    <div className="ProductPage bg-image" >
      <Header activePage="produits" helmetTitle="Drouaire - Produits" />
      <main>
        <div className="product-page-container">
          <ProductsSection />
        </div>
        <div className='product-page-description-section'>
        <Title title="Notre force" />
          <div className='product-page-description-grid'>
            <div className='product-page-description-column'>
              <p className='product-page-description-title'>SERVICE ET CONSEIL</p>
              <p>Nos experts à votre écoute</p>
            </div>
            <div className='product-page-description-column'>
              <p className='product-page-description-title'>LE + GRAND CHOIX DE LONGUEURS</p>
              <p>En bois de structure en IDF</p>
            </div>
            <div className='product-page-description-column'>
              <p className='product-page-description-title'>STOCK ET DISPONIBILITE</p>
              <p>2500 références en stock<br></br>un approvisionnement constant</p>
            </div>
            <div className='product-page-description-column'>
              <p className='product-page-description-title'>RETRAIT EN 1H</p>
              <p>à l’agence de Chelles</p>
            </div>
            <div className='product-page-description-column'>
              <p className='product-page-description-title'>LIVRAISON 48H</p>
              <p>Des produits en stock</p>
            </div>
            <div className='product-page-description-column'>
              <p className='product-page-description-title'>PAIEMENT SECURISE</p>
              <p>Lors de votre commande</p>
            </div>
          </div>
          {/* <div>
            <h2 className='product-page-description-section-title'>L’un des plus grands choix de longueurs sur l’Ile de France</h2>
          </div> */}
        </div>
        <div className="product-page-contact-section">
          <h2>Vous avez des besoins spécifiques ?</h2>
          <div className="product-page-contact-section-button">
            <NavLink to="/contact">
              <button>Je contacte un expert</button>
            </NavLink>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default ProductPage;