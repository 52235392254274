import { useContext } from 'react';

import { ConfigContext } from '../context/ConfigContext.js';

const useGetReferences = () => {
  const { setReferences } = useContext(ConfigContext);
  
  // Function to fetch references from the backend
  const getReferences = async () => {
    
    try {
      const apiUrl = process.env.REACT_APP_API_URL;

      const response = await fetch(`${apiUrl}/api/reference/getReferences`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setReferences(data.references); // Mettre à jour l'état avec les titres des chats
      
    } catch (error) {
      console.error('Error during reference list retrieval :', error);
    }
  };

  return {
    getReferences
  };

};

export default useGetReferences;