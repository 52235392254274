import React, { createContext, useState } from 'react';

// Create context
export const ConfigContext = createContext();

// Create provider component
export const ConfigProvider = ({ children }) => {
  const [idToken, setIdToken] = useState("idToken");
  const [accessToken, setAccessToken] = useState("accessToken");
  const [userRole, setUserRole] = useState("guest");
  const [activeCategory, setActiveCategory] = useState('charpente-et-structure');

  const [productList, setProductList] = useState([]);

  const [currentEstimation, setCurrentEstimation] = useState( {estimationId: "currentEstimationId", estimationName: `Devis du ${new Date().toISOString().split('T')[0]}`, purchasePlan: [], lastModified: `${new Date().toISOString().split('T')[0]}`} );
  const [estimationList, setEstimationList] = useState([]);

  // State to store the admin creation
  const [news, setNews] = useState({newsId: "defaultNewsId", title: "actu", description: "", link: "", linkText: "", pictureUrl: "https://pixnio.com/fr/media/foret-conifere-majestueux-neigeux-hiver", videoUrl: ""});
  const [references, setReferences] = useState([]);
  const [importedFiles, setImportedFiles] = useState([]); // Ajout de l'état pour les fichiers importés

  // State to control the visibility of the errormodal
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState('');

  // State to control the visibility of the alert modal
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [alertModalMessage, setAlertModalMessage] = useState('');

  // State to control the visibility of the sign in modal
  const [showSignInModal, setShowSignInModal] = useState(false);

  return (
    <ConfigContext.Provider value={{ 
      idToken, setIdToken,
      accessToken, setAccessToken,
      userRole, setUserRole,
      activeCategory, setActiveCategory,
      productList, setProductList,
      currentEstimation, setCurrentEstimation,
      estimationList, setEstimationList,
      news, setNews,
      references, setReferences,
      showErrorModal, setShowErrorModal,
      errorModalMessage, setErrorModalMessage,
      showAlertModal, setShowAlertModal,
      alertModalMessage, setAlertModalMessage,
      showSignInModal, setShowSignInModal,
      importedFiles, setImportedFiles
    }}>
      {children}
    </ConfigContext.Provider>
  );
};