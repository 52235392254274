import { useContext } from 'react';

import { ConfigContext } from '../context/ConfigContext';

const useHandleQuantityChange = () => {
    const { setCurrentEstimation } = useContext(ConfigContext);

    const handleQuantityValueChange = async (product, quantity) => {
        setCurrentEstimation((prevEstimation) => {
            const updatedPurchasePlan = [...prevEstimation.purchasePlan];
            const productIndex = updatedPurchasePlan.findIndex(
                (item) => item.productId === product.productId
            );

            if (productIndex >= 0) {
                // Product is already in the purchase plan
                updatedPurchasePlan[productIndex].quantity = quantity;

                if (quantity === 0) {
                    // Remove the product if quantity reaches 0
                    updatedPurchasePlan.splice(productIndex, 1);
                }
            } else {
                // Product is not in the purchase plan
                if (quantity > 0) {
                    updatedPurchasePlan.push({ productId: product.productId, quantity: quantity, price: product.price, productLength: product.productLength, productSection: product.productSection, denomination: product.denomination });
                }
            }

            return {
                ...prevEstimation,
                purchasePlan: updatedPurchasePlan,
                lastModified: "En cours",
            };
        });
        
    };
    

    const handleQuantityChange = async (product, operation) => {
        setCurrentEstimation((prevEstimation) => {
            const updatedPurchasePlan = [...prevEstimation.purchasePlan];
            const productIndex = updatedPurchasePlan.findIndex(
                (item) => item.productId === product.productId
            );

            if (productIndex >= 0) {
                // Product is already in the purchase plan
                if (operation === 'increment') {
                    updatedPurchasePlan[productIndex].quantity += 1;
                } else if (operation === 'decrement') {
                    if (updatedPurchasePlan[productIndex].quantity > 1) {
                        updatedPurchasePlan[productIndex].quantity -= 1;
                    } else {
                        // Remove the product if quantity reaches 0
                        updatedPurchasePlan.splice(productIndex, 1);
                    }
                }
            } else {
                // Product is not in the purchase plan
                if (operation === 'increment') {
                    updatedPurchasePlan.push({ productId: product.productId, quantity: 1, price: product.price, productLength: product.productLength, productSection: product.productSection, denomination: product.denomination });
                }
            }

            return {
                ...prevEstimation,
                purchasePlan: updatedPurchasePlan,
                lastModified: "En cours",
            };
        });
    };

    return { handleQuantityChange, handleQuantityValueChange };
};

export default useHandleQuantityChange;