import { useContext } from 'react';

import { ConfigContext } from '../context/ConfigContext.js';

const useUpdateEstimationName = () => {
  const { idToken, accessToken, currentEstimation, setCurrentEstimation, estimationList, setEstimationList } = useContext(ConfigContext);
  
  // Function to update estimation name
  const updateEstimationName = async (newEstimationName) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      
      const response = await fetch(`${apiUrl}/api/estimation/updateEstimationName`, {
        method: 'POST',
        headers: {
          'X-ID-Token': idToken,
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ estimationId: currentEstimation.estimationId, newEstimationName: newEstimationName }), // Pass estimationId and newName
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setEstimationList(estimationList.map((estimation) => (estimation.estimationId === data.estimationId ? { ...estimation, estimationName: data.newEstimationName } : estimation)));
      setCurrentEstimation({...currentEstimation, estimationName: data.newEstimationName});
      
    } catch (error) {
      console.error('Erreur lors de la mise à jour du titre du estimation :', error);
    }
  };

  return { updateEstimationName };
};

export default useUpdateEstimationName;