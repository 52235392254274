import React from "react";
import "./LoadingIconButton.css";
import LoadingSpinner from "./LoadingSpinner";
import Icon from "../icons/Icon";

const LoadingIconButton = ({ isLoading, icon, onClick, className="", ...props }) => {
  return (
    <button
      className={`loading-icon-button icon-button ${isLoading ? "loading" : ""} ${className}`}
      onClick={onClick}
      disabled={isLoading} // Désactivé pendant le chargement
      {...props}
    >

      {isLoading && <LoadingSpinner onPrimary={className.includes("primary-button")} size="1.5rem"/>}
      {!isLoading && <Icon name={icon} size="2rem" />}

    </button>
  );
};

export default LoadingIconButton;
