import { Authenticator, useTheme, View, Text, Button, Image, useAuthenticator, validateCustomSignUp } from '@aws-amplify/ui-react';
import { SelectField, CheckboxField } from '@aws-amplify/ui-react';

import { useNavigate } from 'react-router-dom';

import Header from '../../components/layout/Header';
import LoadingIndicator from '../../components/sections/LoadingIndicator';


const components = {
  Header() {
    const { tokens } = useTheme();
    return (
      <View textAlign="center" padding={tokens.space.large}>
        <div>
          <Header activePage="mon-espace-client" helmetTitle="Drouaire - Mon Espace Client"/>
        </div>
      </View>
    );
  },

  SignIn: {
    Header() {
      // return <h3 className='pi-8 mb-0 bold'>Se connecter</h3>;
    },
    Footer() {
      const { toForgotPassword, toSignUp } = useAuthenticator();
      return (
        <View className="text-center mt-4 space-y-2">
          <Button
            onClick={toForgotPassword}
            className="button-forgot-password"
          >
            Mot de passe oublié ?
          </Button>
          {/* <Button
            onClick={toSignUp}
            className="button-sign-up"
          >
            Créer un compte
          </Button> */}
        </View>
      );
    }
  },

  SignUp: {
    Header() {
      // return <h3 className='pi-8 mb-0'>Créer un compte</h3>;
    },
    FormFields() {
      const { validationErrors } = useAuthenticator();

      return (
        <>
          <Authenticator.SignUp.FormFields />

          <SelectField
            name="custom:optimization_regime"
            label="Régime d'optimisation"
            placeholder="Choisissez votre régime"
            required
          >
            <option value="particulier">Particulier</option>
            <option value="charpentier">Charpentier</option>
            <option value="bureau_d_etude">Bureau d'étude</option>
          </SelectField>

          {/* Affichage des erreurs de validation */}
          {validationErrors['custom:optimization_regime'] && (
            <Text color="red">{validationErrors['custom:optimization_regime']}</Text>
          )}

        </>

      );
    },
    Footer() {
      const { toSignIn } = useAuthenticator();
      return (
        <View className="text-center">
          <Button
            onClick={toSignIn}
            className="button-back-to-sign-in"
          >
            Retour à la connexion
          </Button>
        </View>
      );
    }
  },

  ForgotPassword: {
    Header() {
      return <Text className="text-xl font-semibold mb-4">Réinitialisation du mot de passe</Text>;
    }
  }
};

const formFields = {
  signIn: {
    username: {
      placeholder: 'Entrez votre email',
      label: 'Email',
      isRequired: true,
    },
    password: {
      placeholder: 'Entrez votre mot de passe',
      label: 'Mot de passe',
      isRequired: true,
    }
  },
  signUp: {
    email: {
      placeholder: 'Entrez votre email',
      label: 'Email',
      isRequired: true,
      order: 1,
    },
    password: {
      placeholder: 'Créez votre mot de passe',
      label: 'Mot de passe',
      isRequired: true,
      order: 2
    },
    confirm_password: {
      placeholder: 'Confirmez votre mot de passe',
      label: 'Confirmation du mot de passe',
      isRequired: true,
      order: 3
    },
    family_name: {
      placeholder: 'Entrez votre nom de famille',
      label: 'Nom de famille',
      isRequired: true,
      order: 4
    },
    given_name: {
      placeholder: 'Entrez votre prénom',
      label: 'Prénom',
      isRequired: true,
      order: 5
    },
    phone_number: {
      placeholder: 'Entrez votre numéro de téléphone',
      label: 'Numéro de téléphone',
      isRequired: true,
      order: 6,
      dialCode: '+33',
      dialCodeList: ['+33', '+7', '+36', '+40', '+359', '+381', '']
    }
  },
  forgotPassword: {
    username: {
      placeholder: 'Entrez votre email',
      label: 'Email',
      isRequired: true
    }
  }
};

const signUpAttributes = [
  // 'name',
  'phone_number',
  // 'custom:custom:phone',
  'custom:optimization_regime'
];

export const withAuthenticatorOptions = {
  // theme: customTheme,
  formFields: formFields,
  signUpAttributes: signUpAttributes,
  components: components,
  // usernameAlias: "email"
}

const AuthComponent = () => {
  const navigate = useNavigate();

  const handleUserRedirection = () => {
    navigate('/mon-espace-client');
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="max-w-md w-full p-6 bg-white rounded-lg shadow-md">
        <Authenticator
          formFields={formFields}
          components={components}
          signUpAttributes={signUpAttributes}
          usernameAlias="email"
        >
          {( signOut, user ) => {
            useEffect(() => {
              if (user) {
                handleUserRedirection();
              }
            }, [user]);
            
            return <LoadingIndicator loadingText="Connexion en cours..." />;
          }}
        </Authenticator>
      </div>
    </div>
  );
};

export default AuthComponent;