import React, { useState } from 'react';
import './RseSection.css';
import Title from '../layout/Title';
import Icon from '../icons/Icon';

export const rse = [
  {
    id: 'environnement',
    title: 'Environnement',
    description: 'Des solutions en place pour optimiser nos transports et nos plans de tournées.',
    icon: "leaf",
    additionalText: 'Texte à venir'
  },
  {
    id: 'madeinfrance',
    title: 'Made in France',
    description: '90% de nos bois sont issus de forêts françaises.',
    icon: "flag",
    additionalText: 'Texte à venir'
  },
  {
    id: 'ethique',
    title: 'Éthique',
    description: 'Des fournisseurs choisis pour leur engagement éthique et éco-responsable.',
    icon: "contract",
    additionalText: 'Texte à venir'
  },
  {
    id: 'qualite',
    title: 'Qualité durable',
    description: "25 ans d'expertise dans le négoce du bois et de confiance client.",
    icon: "star",
    additionalText: 'Texte à venir'
  },
];

const RseSection = () => {
  const [selectedRse, setSelectedRse] = useState(null);

  const handleRseClick = (rse) => {
    setSelectedRse(rse);
  };

  const closeModal = () => {
    setSelectedRse(null);
  };

  return (
    <>
      <Title title="Notre démarche RSE" />
      <div className="rse-grid">
        {rse.map((rse) => (
          <div
            key={rse.id}
            className="rse-card"
            onClick={() => handleRseClick(rse)}
          >
            <div className="rse-image-container">
              <Icon name={rse.icon} />
            </div>
            <div className="rse-content">
              <h4><span>{rse.title}</span></h4>
              <p>{rse.description}</p>
            </div>
          </div>
        ))}
      </div>

      {selectedRse && (
        <div className="rse-modal-overlay" onClick={closeModal}>
          <div className="rse-modal" onClick={e => e.stopPropagation()}>
            <div className="rse-modal-content">
              <div className='rse-modal-content-title'>
                <Icon name={selectedRse.icon} />
                <h3>{selectedRse.title}</h3>
              </div>
              <hr></hr>
              <div className="rse-modal-content-text">
                <p>{selectedRse.description}</p>
                <p>{selectedRse.additionalText}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RseSection; 