import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { withAuthenticatorOptions } from '../auth/AuthComponent';

import { ConfigContext } from '../../context/ConfigContext.js';
import useUploadFileToS3 from '../../hooks/useUploadFileToS3.js';
import useSaveReference from '../../hooks/useSaveReference.js';
import useGetAllEstimation from '../../hooks/useGetAllEstimation.js';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import ImportSection from '../../components/common/ImportSection';
import Modal from '../../components/layout/Modal';
import LoadingButton from '../../components/common/LoadingButton.jsx';
import { ReferenceItem } from '../../components/sections/ReferencesSection';
import '../../main.css';
import './ReferenceCreationPage.css';

const ReferenceCreationPage = () => {
  const { userRole, importedFiles, setImportedFiles } = useContext(ConfigContext);

  const location = useLocation();
  const { isEditing, oldData } = location.state || { isEditing: false, oldData: {} };

  const [formData, setFormData] = useState({
    referenceId: isEditing ? oldData.referenceId || '' : 'newReferenceId',
    referenceName: isEditing ? oldData.referenceName || '' : '',
    referenceLocation: isEditing ? oldData.referenceLocation || '' : '',
    photos: isEditing ? oldData.photos || [null, null, null] : [null, null, null],
    period: isEditing ? oldData.period || '' : '',
    surface: isEditing ? oldData.surface || '' : '',
    description: isEditing ? oldData.description || '' : '',
    stakeHolders: isEditing ? oldData.stakeHolders || '' : '',
    products: isEditing ? oldData.products || '' : ''
  });

  const [showPreview, setShowPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { uploadFileToS3 } = useUploadFileToS3();
  const { saveReference } = useSaveReference();
  const { getAllEstimation } = useGetAllEstimation();

  useEffect(() => {
    setImportedFiles([]);
    getAllEstimation();
  }, []);

  // Redirect to /my-account if currentEstimation is not available
  useEffect(() => {
    if (userRole !== 'admin') {
      navigate('/mon-espace-client');
    }
  }, [userRole, navigate]);

  useEffect(() => {
    console.log("The importedFiles are:", importedFiles);
    setFormData((prevFormData) => ({
      ...prevFormData,
      photos: importedFiles,
    }));
  }, [importedFiles, setImportedFiles]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Initialiser un tableau pour stocker les clés S3
    const s3Keys = [];
    console.log("importedFiles", importedFiles);
    // Boucle sur importedFiles pour uploader chaque fichier
    for (const file of importedFiles) {
      if (file) { // Vérifie si le fichier existe
        const s3Key = await uploadFileToS3(file);
        s3Keys.push(s3Key); // Ajouter la clé S3 au tableau
      }
    }

    console.log("The s3Keys:", s3Keys);
    console.log("The formData:", formData);

    await saveReference(
      formData.referenceId,
      formData.referenceName,
      formData.referenceLocation,
      formData.period,
      formData.surface,
      formData.description,
      formData.stakeHolders,
      formData.products,
      s3Keys,
      setIsLoading
    );

    navigate('/mon-espace-client');
  };

  return (
    // <div className="ReferenceCreationPage bg-image" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/abstract-wooden-bg-03.svg)` }}>
    <div className="ReferenceCreationPage bg-image" >
      <Header activePage="realisations" helmetTitle="Création de réalisation" />
      <main>
        <div className="reference-creation-page-header">
          <h1>Création d'une réalisations</h1>
          <p>Ajoutez une nouvelle réalisation de projet qui sera affichée dans la section "Réalisations".</p>
        </div>

        <form className="reference-creation-form" onSubmit={handleSubmit}>
          <div className="reference-creation-form-section">
            <h3>Informations principales</h3>
            <div className="form-group">
              <label htmlFor="project">Projet *</label>
              <input
                type="text"
                id="project"
                name="referenceName"
                placeholder="ex: Centre aquatique olympique"
                value={formData.referenceName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="location">Lieu</label>
              <input
                type="text"
                id="location"
                name="referenceLocation"
                placeholder="ex: Saint-Denis (93)"
                value={formData.referenceLocation}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="reference-creation-form-section">
            <h3>Photos du projet</h3>
            <p className="reference-creation-field-description">
              Vous pouvez ajouter jusqu'à 3 photos du projet.
            </p>
            <div className="reference-photos-container">

              <ImportSection
                importType="multiple"
                importText="Importer jusqu'à trois photos ordonnées alphabétiquement."
              />

            </div>
          </div>

          <div className="reference-creation-form-section">
            <h3>Détails du projet</h3>
            <div className="form-group">
              <label htmlFor="period">Période</label>
              <input
                type="text"
                id="period"
                name="period"
                placeholder="ex: 2018-2025"
                value={formData.period}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="surface">Surface</label>
              <input
                type="text"
                id="surface"
                name="surface"
                placeholder="ex: 20 000 m2 environ"
                value={formData.surface}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="description">Descriptif</label>
              <textarea
                id="description"
                name="description"
                placeholder="Description détaillée du projet..."
                value={formData.description}
                onChange={handleChange}
                rows={5}
              />
            </div>
          </div>

          <div className="reference-creation-form-section">
            <h3>Informations complémentaires</h3>
            <div className="form-group">
              <label htmlFor="stakeHolders">Intervenants</label>
              <textarea
                id="stakeHolders"
                name="stakeHolders"
                placeholder="Liste des intervenants (maître d'ouvrage, maître d'œuvre, autres corps de métier...)"
                value={formData.stakeHolders}
                onChange={handleChange}
                rows={4}
              />
            </div>
            <div className="form-group">
              <label htmlFor="productTypes">Type de produits fournis</label>
              <textarea
                id="products"
                name="products"
                placeholder="ex: Douglas"
                value={formData.products}
                onChange={handleChange}
                rows={2}
              />
            </div>
          </div>

          <div className="reference-creation-form-actions">
            <button
              type="button"
              className="reference-creation-preview-button secondary-button"
              onClick={() => setShowPreview(true)}
            >
              Aperçu
            </button>
            <LoadingButton
              isLoading={isLoading}
              text={isEditing ? "Modifier la réalisation" : "Créer la réalisation"}
              className="reference-creation-submit-button primary-button"
              // onClick={handleSubmit}
              type="submit"
            />
          </div>
        </form>

        {showPreview && (
          <Modal onClose={() => setShowPreview(false)}>
            <div className="reference-creation-preview-modal">
              <p><strong>Aperçu de la réalisation</strong></p>
              <ReferenceItem key={0} referenceItem={formData} isEditable={false} />
            </div>
          </Modal>
        )}
      </main>
      <Footer />
    </div>
  );
};

export default withAuthenticator(ReferenceCreationPage,  withAuthenticatorOptions);