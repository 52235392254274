import React, { useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import emailjs from 'emailjs-com';
import ReCAPTCHA from 'react-google-recaptcha';
import './Newsletter.css';

const Newsletter = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [status, setStatus] = useState('');
  const [submitStatus, setSubmitStatus] = useState('S\'inscrire');
  const recaptchaRef = useRef();


  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError('Veuillez entrer une adresse email valide');
      return;
    }

    if (!termsAccepted) {
      return;
    }

    const captchaValue = recaptchaRef.current.getValue();
    if (!captchaValue) {
      setStatus('Veuillez valider le captcha');
      return;
    }

    const templateParams = {
      name: '',
      email: email,
      phone: '',
      budget: '',
      company: '',
      message: 'Je souhaite m\'inscrire à votre newsletter',
    };

    setSubmitStatus('...');

    emailjs.send('service_jiq187f', 'template_y508rco', templateParams, 'Ge1LwLM71Yj1aFS_J')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setStatus('Merci pour votre inscription !');
        setSubmitStatus('Inscrit');
        setEmail('');
        setTermsAccepted(false);
        setEmailError('');
        recaptchaRef.current.reset();
        setTimeout(() => {
          setSubmitStatus('S\'inscrire');
          setStatus('');
        }, 3000);
      })
      .catch((error) => {
        console.error('FAILED...', error);
        setStatus('Une erreur est survenue. Veuillez réessayer.');
        setSubmitStatus('Erreur');
        recaptchaRef.current.reset();
        setTimeout(() => {
          setSubmitStatus('S\'inscrire');
          setStatus('');
        }, 3000);
      });
  };

  return (
    <div className="newsletter-section">
      <p>Recevez nos informations commerciales !</p>

      <form className="newsletter-form" onSubmit={handleSubmit}>
        <div className="input-group">
          <input
            type="email"
            placeholder="Votre e-mail *"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError('');
            }}
            required
          />
          {emailError && <span className="error-message">{emailError}</span>}
        </div>

        <div className="custom-checkbox-group">
          <label className="custom-checkbox">
            <input
              type="checkbox"
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
              required
            />
            <span className="checkmark-box">
              <span
                className={termsAccepted ? 'checkmark checked' : 'checkmark'}
              ></span>
              {termsAccepted && <span className="checkmark-after"></span>}
            </span>
            <span className="checkbox-label">
              <span>J'accepte les </span>
              <NavLink to="/mentions-legales">Mentions légales </NavLink>
              <span> & la </span><NavLink to="/politique-de-confidentialite">Politique de confidentialité</NavLink>
            </span>
          </label>
        </div>

        <div className='footer-captcha'>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LdoP8AqAAAAAIHkTwZOLivyp9HyQsJjhtcwvVak"
            size="normal"
          />
        </div>


        <button
          className={`footer-button ${(!email || !termsAccepted) ? 'disabled' : ''}`}
          type="submit"
          disabled={!email || !termsAccepted}
        >
          {submitStatus}
        </button>
      </form>
      {status && <p className="status-message">{status}</p>}
    </div>
  );
};

export default Newsletter; 