import { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { ConfigContext } from '../context/ConfigContext.js';


const useSaveEstimation = () => {
  const { idToken, accessToken, setCurrentEstimation } = useContext(ConfigContext);

  // Function to fetch chat history from the backend
  const saveEstimation = async (currentEstimation, setIsSaving) => {
    try {
      if (setIsSaving) {
        setIsSaving(true);
      }
      
      const apiUrl = process.env.REACT_APP_API_URL;

      const response = await fetch(`${apiUrl}/api/estimation/saveEstimation`, {
        method: 'POST',
        headers: {
          'X-ID-Token': idToken,
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          estimationId: currentEstimation.estimationId, 
          estimationName: currentEstimation.estimationName, 
          newPurchasePlan: currentEstimation.purchasePlan }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      //console.log("Estimation successfully saved", data);
      
    } catch (error) {
      console.error('Error during estimation list retrieval :', error);
    }
    if (setIsSaving) {
      setIsSaving(false);
    }
  };

  return { saveEstimation };
};

export default useSaveEstimation;