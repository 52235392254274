import React from 'react';
import '../../main.css';
import './LegalPage.css';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';


const LegalNoticePage = () => {
  return (
    <div className="LegalNoticePage">
      <Header activePage="legal-notice" helmetTitle = "DivinData - Mentions légales"/>
      <main>
        
        <section className="legal-notice-section page">
            <h2>Mentions légales</h2>
            <h3>Présentation du site</h3>

            <p>Le présent site "www.drouaire-bois.com" est la propriété de "DROUAIRE BOIS PANNEAUX LAMBRIS", immatriculée au registre du commerce et des sociétés de MEAUX sous le numéro 324 884 469, dont le siège social est situé 63 Avenue du Marechal Foch, 77500 CHELLES</p>
            <p>Le directeur de la publication est Monsieur Francois LARESCHE.</p>
            <p>Le site est hébergé par la société Amazon Web Services EMEA SARL, 38 Avenue John F. Kennedy, L-1855, Luxembourg, sur des serveurs situés en Europe.</p>
        
            <h3>Propriété intellectuelle</h3>
            <p>Tous les droits de reproduction sont réservés, y compris pour les documents iconographiques et photographiques.</p>

            <p>DROUAIRE BOIS PANNEAUX LAMBRIS décline toute responsabilité quant aux erreurs ou dysfonctionnements pouvant survenir sur le site <strong>www.drouaire-bois.com</strong>.
            Le site peut contenir des liens vers d'autres sites pour lesquels nous ne sommes pas responsables des contenus et des pratiques.</p>

            <h3>Protection des données personnelles</h3>
            <p>DROUAIRE BOIS PANNEAUX LAMBRIS s'engage à ce que la collecte et le traitement de vos données, effectués à partir du site <strong>www.drouaire-bois.com</strong>, soient conformes au Règlement Général sur la Protection des Données (RGPD).
            Vous disposez d’un droit d’accès, de rectification, de suppression et d’opposition de vos données personnelles en nous contactant à l’adresse : <strong>contact@drouaire-bois.com</strong>.</p>
        
            
            <h3>Conception Réalisation & Maintenance</h3>
            <p>Le site <strong>www.drouaire-bois.com</strong> est conçu, réalisé et maintenu par <a href="https://www.divindata.com">DivinData</a>.</p>
            <ul>
                <li><strong>Email :</strong> <a href="mailto:contact@divindata.com">contact@divindata.com</a></li>
                <li><strong>Informations :</strong> <a href="https://www.divindata.com">www.divindata.com</a></li>
            </ul>
   
        </section>

      </main>
      <Footer />
      
    </div>
  );
}

export default LegalNoticePage;