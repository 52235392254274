import React, { useState } from 'react';
import './ServicesSection.css';
import Title from '../../components/layout/Title';

export const services = [
  {
    id: 'ingenierie',
    title: 'INGENIERIE',
    description: 'Un soutien technique pour vos réalisations et la validation technique de vos projets.',
    image: `${process.env.PUBLIC_URL}/services/ingenierie.webp?v=${new Date().getTime()}`,
    additionalText: (
      <>
        <p>Des équipes proches de vous :</p>
        <ul>
          <li>Accompagnement dans vos demandes spécifiques</li>
          <li>Conseil dans le choix des produits</li>
          <li>Aide dans l’étude de vos plans, la réalisation de vos métrés et éventuels calepinages</li>
          <li>Fourniture d’échantillons si besoin</li>
        </ul>
      </>
    )
  },
  {
    id: 'prestations',
    title: 'PRESTATIONS',
    description: "Répondre au besoin de transformation de vos produits.",
    image: `${process.env.PUBLIC_URL}/services/prestations.webp?v=${new Date().getTime()}`,
    additionalText: (
      <>
        <p>Débit de charpente sur liste :</p>
        <ul>
          <li>Rabotage & Profilage</li>
          <li>Taille</li>
          <li>Coupe en bout</li>
          <li>Découpe panneaux</li>
          <li>Placage</li>
          <li>Défonçage</li>
        </ul>
      </>
    )
  },
  {
    id: 'traitements',
    title: 'TRAITEMENTS',
    description: 'Garantir la durabilité des produits correspondant aux classes de risque biologique de leur usage.',
    image: `${process.env.PUBLIC_URL}/services/traitement-autoclave.webp?v=${new Date().getTime()}`,
    additionalText: (
      <>
        <p>par trempage :</p>
        <ul>
          <li>Correspondant à la classe de risque 2</li>
          <li>Incolore ou jaune</li>
        </ul>
        
        <p>autoclave :</p>
        <ul>
          <li>Correspondant aux classes de risque 3 ou 4</li>
          <li>Vert/ brun/ noir/ incolore</li>
        </ul>
      </>
    )
  },
  {
    id: 'logistique',
    title: 'LOGISTIQUE',
    description: "Un service entièrement intégré pour assurer une livraison de vos marchandises dans les meilleures conditions.",
    image: `${process.env.PUBLIC_URL}/services/logistique.webp?v=${new Date().getTime()}`,
    additionalText: (
      <>
        <ul>
          <li>Personnalisation dans la préparation</li>
          <li>Emballage et protection</li>
          <li>Transport par camions-grues avec chauffeurs spécialisés</li>
          <li>Livraison sur chantiers dans toute l’Ile de France (75, 77, 78, 91, 92, 93, 94, 95)</li>
          <li>Livraison possible en province sur demande</li>
        </ul>
      </>
    )
  }
];

const ServicesSection = () => {
  const [selectedService, setSelectedService] = useState(null);

  const handleServiceClick = (service) => {
    setSelectedService(service);
  };

  const closeModal = () => {
    setSelectedService(null);
  };

  return (
    <>
      <Title title="Services" />
      <div className="services-grid">
        {services.map((service) => (
          <div 
            key={service.id} 
            className="service-card"
            onClick={() => handleServiceClick(service)}
          >
            <div className="service-image-container">
              <img src={service.image} alt={service.title} />
            </div>
            <div className="service-content">
              <h4><span>{service.title}</span></h4>
              <p>{service.description}</p>
            </div>
          </div>
        ))}
      </div>

      {selectedService && (
        <div className="service-modal-overlay" onClick={closeModal}>
          <div className="service-modal" onClick={e => e.stopPropagation()}>
            <div className="service-modal-content">
              <h3>{selectedService.title}</h3>
              <hr></hr>
              
              {selectedService.additionalText}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ServicesSection; 