import { useContext, useEffect } from 'react';
import { fetchAuthSession } from '@aws-amplify/auth';

import { ConfigContext } from '../context/ConfigContext';


const useGetTokens = () => {
  const { setIdToken, setAccessToken } = useContext(ConfigContext);

  const getTokens = async () => {
    try {
      const session = await fetchAuthSession(); // Obtenir la session actuelle

      const idToken = session.tokens.idToken.toString(); // Récupérer le jeton JWT
      console.log("ID Token retrieved");
      setIdToken(idToken);

      const accessToken = session.tokens.accessToken.toString(); // Récupérer le jeton JWT
      console.log("Access Token retrieved");
      setAccessToken(accessToken);

      return { idToken, accessToken, statusCode: "200" };

    } catch (err) {
      console.log('Error retrieving Cognito session:', err);
      return { idToken: null, accessToken: null, statusCode: "500" };
    }
  };

  return { getTokens };
};

export default useGetTokens;