import React, { useState, useEffect, useContext, use } from 'react';

import { ConfigContext } from '../../context/ConfigContext.js';
import useGetProductListByCategory from '../../hooks/useGetProductListByCategory.js';
import useHandleQuantityChange from '../../hooks/useHandleQuantityChange.js';
import useSaveEstimation from '../../hooks/useSaveEstimation.js';
import Header from '../../components/layout/Header.js';
import Footer from '../../components/layout/Footer.js';
import './ProductDetailPage.css';
import Icon from '../../components/icons/Icon.jsx';
import { NavLink } from 'react-router-dom';
import QuantityControl from '../../components/common/QuantityControl.js';
import { FaSortAmountDown, FaSortAmountUp, FaSort } from 'react-icons/fa';

import { products } from '../../components/sections/ProductsSection.js';
import { useNavigate } from 'react-router-dom';

const ProductDetailPage = ({ pageCategory, productCategorieData, quote }) => {
  if (!productCategorieData) {
      productCategorieData = [
        {
        name: "Charpente Sapin",
        title: "Sapin épicéa traité classe 2",
        subtitle: "Charpente et structure",
        characteristics: {
          essence: "Épicéa et sapin mélangés",
          provenance: "Haut Doubs",
          certifications: [
            "PEFC n° 10-31-531",
            "ISO 14001 par Veritas",
            "CE n°0380-CPD-4069",
            "Classement structurel : C24"
          ],
          specifications: [
            "Finition : scié brut",
            "Seccité : frais de sciage",
            "Traitement : trempage cl2"
          ]
        }
      }, {
        name: "Charpente Chêne",
        title: "Chêne traité classe 2",
        subtitle: "Charpente et structure",
        characteristics: {
          essence: "Épicéa et sapin mélangés",
          provenance: "Haut Doubs",
          certifications: [
            "PEFC n° 10-31-531",
            "ISO 14001 par Veritas",
            "CE n°0380-CPD-4069",
            "Classement structurel : C24"
          ],
          specifications: [
            "Finition : scié brut",
            "Seccité : frais de sciage",
            "Traitement : trempage cl2"
          ]
        },
      }, {
        name: "Charpente Douglas",
        title: "Douglas traité classe 2",
        subtitle: "Charpente et structure",
        characteristics: {
          essence: "Épicéa et sapin mélangés",
          provenance: "Haut Doubs",
          certifications: [
            "PEFC n° 10-31-531",
            "ISO 14001 par Veritas",
            "CE n°0380-CPD-4069",
            "Classement structurel : C24"
          ],
          specifications: [
            "Finition : scié brut",
            "Seccité : frais de sciage",
            "Traitement : trempage cl2"
          ]
        },
      },
    
    ];
  }

  
  const navigate = useNavigate();

  const { userRole, activeCategory, setActiveCategory, productList, currentEstimation, setCurrentEstimation } = useContext(ConfigContext);
  const [selectedSubCategory, setSelectedSubCategory] = useState('Charpente Sapin');
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [showAllProducts, setShowAllProducts] = useState(false);
  const [sortConfigs, setSortConfigs] = useState({});
  const [expandedTypes, setExpandedTypes] = useState({});
  
  const { handleQuantityChange, handleQuantityValueChange } = useHandleQuantityChange();
  const { saveEstimation } = useSaveEstimation();

  useEffect(() => {
    setActiveCategory(pageCategory);
  }, [pageCategory]);

  useEffect(() => {
    setIsOpen(false);
  }, [activeCategory]);

  useGetProductListByCategory(setLoading);

  const [subcategoryDetails, setSubcategoryDetails] = useState(productCategorieData);

  // Get details of the selected subcategory
  const currentDetails = subcategoryDetails.find(
    (subcategory) => subcategory.name === selectedSubCategory
  );

  const handleEditEstimate = () => {
    console.log("currentEstimation", currentEstimation);
    navigate(`/mon-espace-client/devis`);
  };

  const handleEditPurchasePlan = (product, operation) => {
    handleQuantityChange(product, operation);
    if (userRole === 'user' && currentEstimation.estimationId !== 'currentEstimationId') {
        saveEstimation(currentEstimation);
    }
  };
  const handleEditQuantity = (product, quantity) => {
    handleQuantityValueChange(product, quantity);
    if (userRole === 'user' && currentEstimation.estimationId !== 'currentEstimationId') {
        saveEstimation(currentEstimation);
    }
  };

  

  const handleNewEstimate = () => {
    setCurrentEstimation({
      estimationId: "currentEstimationId", 
      estimationName: `Devis du ${new Date().toISOString().split('T')[0]}`, 
      purchasePlan: [], 
      lastModified: `${new Date().toISOString().split('T')[0]}`
    });
  };

  const calculateTotal = () => {
    return currentEstimation.purchasePlan.reduce((total, item) => {
      return total + (item.quantity || 0) * (item.price || 0);
    }, 0);
  };

  // //////////////////////////////////// RENDER FUNCTIONS ////////////////////////////////////
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const footer = document.querySelector('footer');
  //     const totalFooter = document.querySelector('.product-detail-total-footer');
      
  //     if (!footer || !totalFooter) return;
      
  //     const footerRect = footer.getBoundingClientRect();
  //     const windowHeight = window.innerHeight;
      
  //     if (footerRect.top <= windowHeight) {
  //       totalFooter.classList.add('absolute');
  //     } else {
  //       totalFooter.classList.remove('absolute');
  //     }
  //   };

  //   window.addEventListener('scroll', handleScroll);
  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, []);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const getInitialSortedProducts = (products, pieceType) => {
    return [...products].sort((a, b) => {
      // Tri par Section d'abord
      const sectionComparison = a.productSection.localeCompare(b.productSection, undefined, { numeric: true });
      if (sectionComparison !== 0) return sectionComparison;
      // Puis par Longueur
      return parseFloat(a.productLength) - parseFloat(b.productLength);
    });
  };

  const requestSort = (key, pieceType) => {
    setSortConfigs(prevConfigs => {
      const config = prevConfigs[pieceType] || { primary: null, secondary: null };
      
      if (!config.primary) {
        // Premier tri
        return { ...prevConfigs, [pieceType]: { primary: { key, direction: 'ascending' }, secondary: null }};
      }
      
      if (config.primary.key === key) {
        // Inverser la direction si on clique sur la même colonne
        return {
          ...prevConfigs,
          [pieceType]: {
            primary: { key, direction: config.primary.direction === 'ascending' ? 'descending' : 'ascending' },
            secondary: config.secondary
          }
        };
      }
      
      // Nouvelle colonne : l'ancien tri primaire devient secondaire
      return {
        ...prevConfigs,
        [pieceType]: {
          primary: { key, direction: 'ascending' },
          secondary: config.primary
        }
      };
    });
  };

  const sortProducts = (products, pieceType) => {
    const config = sortConfigs[pieceType];
    if (!config || !config.primary) {
      return getInitialSortedProducts(products, pieceType);
    }

    return [...products].sort((a, b) => {
      // Fonction helper pour comparer les valeurs
      const compare = (aVal, bVal, direction) => {
        const aNum = parseFloat(aVal);
        const bNum = parseFloat(bVal);
        const multiplier = direction === 'ascending' ? 1 : -1;
        return (aNum - bNum) * multiplier;
      };

      // Appliquer le tri primaire
      const primaryA = a[config.primary.key];
      const primaryB = b[config.primary.key];
      const primaryResult = compare(primaryA, primaryB, config.primary.direction);
      
      // Si les valeurs primaires sont égales et qu'il y a un tri secondaire
      if (primaryResult === 0 && config.secondary) {
        const secondaryA = a[config.secondary.key];
        const secondaryB = b[config.secondary.key];
        return compare(secondaryA, secondaryB, config.secondary.direction);
      }
      
      return primaryResult;
    });
  };

  // //////////////////////////////////// JSX ////////////////////////////////////

  return (
    <div className="ProductDetailPage">
      <Header activePage="produits" />
      <main>
        <section className="product-detail-page-header">
          <div className="product-detail-product-type-banner">
          {/* <div className="product-detail-product-type-banner dark"> */}
            <div className="product-detail-product-type-banner-container">
              <button className="product-detail-banner-button" onClick={toggleOpen}>
                <h2>{products[activeCategory].title}</h2>
                <div className={`product-detail-banner-arrow-container ${isOpen ? 'open' : ''}`}>
                  <Icon name={`keyboard_arrow_down`} />        
                </div>
              </button>
              <div className={`product-detail-banner-type-list ${isOpen ? 'open' : ''}`}>
                {Object.values(products).map((product, index) => (
                  <NavLink key={index} to={`/produits/${product.path}`}>
                    <span className={`product-detail-banner-type-list-item ${activeCategory === product.id ? 'active' : ''}`}>
                      {product.title}
                    </span>
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
          <p className="quote">{quote}</p>
        </section>

        <div className="product-detail-subcategory-tabs">
          {subcategoryDetails.map((subcategory, index) => (
            <button
              key={index}
              className={selectedSubCategory === subcategory.name ? 'active' : ''}
              onClick={() => setSelectedSubCategory(subcategory.name)}
            >
              {subcategory.name}
            </button>
          ))}
        </div>

        <section className="product-detail-product-info bg-image" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/product-image.webp)` }}>
          <p><strong>{currentDetails.title}</strong></p>
          {/* <p className='subtitle'><i>{currentDetails.subtitle}</i></p> */}
          <p className='subtitle'><i>{currentDetails.subtitle}</i></p>

          <div className="product-detail-characteristics">
            <div className="product-detail-row-item">
              <Icon name="forest" />
              <p>{currentDetails.characteristics.essence}</p>
            </div>
            <div className="product-detail-row-item">
              <Icon name="location_on" />
              <p>{currentDetails.characteristics.provenance}</p>
            </div>
            <div className="product-detail-row-item">
              <Icon name="verified_filled" />
              <div>
                {currentDetails.characteristics.certifications.map((cert, index) => (
                  <ul key={index}>
                    <li>{cert}</li>
                  </ul>
                ))}
              </div>
            </div>
            <div className="product-detail-row-item">
              <Icon name="star_half" />
              <div>
                {currentDetails.characteristics.specifications.map((spec, index) => (
                  <ul key={index}>
                    <li>{spec}</li>
                  </ul>
                ))}
              </div>
            </div>
          </div>
        </section>


        <section className="product-detail-product-tables">
          {loading ? (
            <p>Chargement des produits...</p>
          ) : (
            <>
              {[...new Set(productList
                .filter(product => product.subcategory === selectedSubCategory)
                .map(product => product.pieceType))]
                .map(pieceType => (
                  <div key={pieceType} className="product-detail-category-section">
                    <h4>{pieceType}</h4>
                    <table>
                      <thead>
                        <tr>
                          <th>Fiche</th>
                          <th onClick={() => requestSort('productSection', pieceType)} className="sortable-header">
                            Section (mm²)
                            {sortConfigs[pieceType]?.primary?.key === 'productSection' && (
                              sortConfigs[pieceType].primary.direction === 'ascending' ?
                              <FaSortAmountUp className="my-account-sort-icon" /> :
                              <FaSortAmountDown className="my-account-sort-icon" />
                            )}
                          </th>
                          <th onClick={() => requestSort('productLength', pieceType)} className="sortable-header">
                            Longueur (mm)
                            {sortConfigs[pieceType]?.primary?.key === 'productLength' && (
                              sortConfigs[pieceType].primary.direction === 'ascending' ?
                              <FaSortAmountUp className="my-account-sort-icon" /> :
                              <FaSortAmountDown className="my-account-sort-icon" />
                            )}
                          </th>
                          <th>Quantité</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sortProducts(productList
                          .filter(product => 
                            product.subcategory === selectedSubCategory && 
                            product.pieceType === pieceType
                          ), pieceType)
                          .slice(0, expandedTypes[pieceType] ? undefined : 3)
                          .map(product => (
                            <tr key={product.productId}>
                              <td>
                                <div className="product-detail-row-item">
                                  <NavLink to={product.productReferencePath}>
                                    <Icon name="document" />
                                  </NavLink>
                                </div>
                              </td>
                              <td>{product.productSection}</td>
                              <td>{product.productLength}</td>
                              <td>
                                <QuantityControl 
                                  product={product} 
                                  currentEstimation={currentEstimation} 
                                  handleQuantityChange={handleEditPurchasePlan} 
                                  handleQuantityValueChange={handleEditQuantity}
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    {!expandedTypes[pieceType] && 
                      productList.filter(product => 
                        product.subcategory === selectedSubCategory && 
                        product.pieceType === pieceType
                      ).length > 3 && (
                        <div className="show-more-button-container">
                          <button 
                            className="show-more-button secondary-button"
                            onClick={() => setExpandedTypes(prev => ({...prev, [pieceType]: true}))}
                          >
                            Afficher plus
                          </button>
                        </div>
                    )}
                  </div>
                ))}
            </>
          )}
        </section>
        
        <section className="product-detail-total-footer dark">
          <div className="product-detail-total-container">
            <div className="product-detail-total-section">
              <p>TOTAL : {calculateTotal()} €</p>
            </div>
            <div className="product-detail-total-buttons">
              
              <a onClick={handleEditEstimate}>Voir le devis</a>
              {/* <a onClick={handleNewEstimate}>Nouveau devis</a> */}
              
            </div>
          </div>
        </section>
      </main >
      <Footer />
    </div >
  );
};

export default ProductDetailPage; 