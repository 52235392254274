import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { withAuthenticatorOptions } from '../auth/AuthComponent';

import { ConfigContext } from '../../context/ConfigContext.js';
import useUploadFileToS3 from '../../hooks/useUploadFileToS3.js';
import useSaveNews from '../../hooks/useSaveNews.js';
import Modal from '../../components/layout/Modal.js';
import NewsDisplay from '../../components/sections/NewsDisplay.js';
import Header from '../../components/layout/Header.js';
import Footer from '../../components/layout/Footer.js';
import ImportSection from '../../components/common/ImportSection.js';
import '../../main.css';
import './NewsCreationPage.css';
import Icon from '../../components/icons/Icon.jsx';
import LoadingButton from '../../components/common/LoadingButton.jsx';

const NewsCreationPage = ({}) => {
  const { userRole, news, importedFiles, setImportedFiles } = useContext(ConfigContext);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { uploadFileToS3 } = useUploadFileToS3();
  const { saveNews } = useSaveNews();

  const [formData, setFormData] = React.useState({
    title: news.title,
    description: news.description,
    date: news.date,
    link: news.link,
    linkText: news.linkText,
    pictureUrl: news.pictureUrl,
    videoUrl: news.videoUrl,
    mediaFile: null
  });

  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    setImportedFiles([]);
  }, []);

  // Redirect to /my-account if currentEstimation is not available
  useEffect(() => {
    if (userRole !== 'admin') {
      navigate('/mon-espace-client');
    }
  }, [userRole, navigate]);

  useEffect(() => {
    setFormData({
      ...formData,
      mediaFile: importedFiles[0]
    });
  }, [importedFiles]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const removeFile = () => {
    setFormData({
      ...formData,
      mediaFile: null
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log("Données de l'actualité:", formData);
    let s3Key = null;
    if (formData.mediaFile) {
      s3Key = await uploadFileToS3(formData.mediaFile);
    }
    
    console.log("s3Key", s3Key);
    saveNews(
      formData.title,
      formData.description,
      formData.link,
      formData.linkText,
      s3Key,
      setIsLoading
    );
    navigate('/mon-espace-client');
    
  };

  return (
    // <div className="NewsCreationPage bg-image" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/abstract-wooden-bg-03.svg)` }}>
    <div className="NewsCreationPage bg-image" >
      <Header activePage="accueil" helmetTitle="Création d'actualité"/>
      <main>
        <div className="news-creation-page-header">
          <h1>Modification de l'actualité</h1>
          <p className="news-creation-page-description">
            Modifiez l'actualité qui sera affichée sur la page d'accueil du site.
          </p>
        </div>

        <form className="news-creation-form" onSubmit={handleSubmit}>
          <div className="news-creation-form-columns">
            <div className="news-creation-form-column">
              <div className="news-creation-form-section">
                <h3>Informations principales</h3>
                <div className="form-group">
                  <label htmlFor="title">Titre de l'actualité *</label>
                  <input 
                    type="text" 
                    id="title" 
                    name="title"
                    placeholder="ex : Nouveau projet d'envergure" 
                    value={formData.title}
                    onChange={handleChange}
                    required 
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="description">Description</label>
                  <p className="news-creation-field-description">Une description courte et percutante qui apparaîtra sur l'actualité.</p>
                  <textarea 
                    id="description" 
                    name="description"
                    placeholder="ex : Découvrez notre dernier projet..." 
                    value={formData.description}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>

              <div className="news-creation-form-section">
                <h3>Lien de redirection</h3>
                <div className="form-group">
                  <label htmlFor="linkText">Texte du lien</label>
                  <p className="news-creation-field-description">Texte qui apparaîtra sur le lien de redirection.</p>
                  <input 
                    type="text" 
                    id="linkText" 
                    name="linkText"
                    placeholder="ex : Découvrez nos produits" 
                    value={formData.linkText}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="link">Lien de redirection</label>
                  <p className="news-creation-field-description">Lien vers lequel les utilisateurs seront redirigés en cliquant sur l'actualité.</p>
                  <input 
                    type="url" 
                    id="link" 
                    name="link"
                    placeholder="ex : https://www.drouaire-bois.com/produits" 
                    value={formData.link}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="news-creation-form-section">
                <h3>Média</h3>
                                
                  <div className="form-group">
                    
                        <label htmlFor="mediaFile">Image</label>
                        <div className="news-creation-media-input-group">
                          <ImportSection
                            importType="single"
                            importText="Importez une image"                           
                          />
                        </div>
                      
                  </div>
                  
              </div>
            </div>
          </div>

          <div className="news-creation-form-actions">
            <button type="button" className="news-creation-preview-button secondary-button" onClick={() => setShowPreview(true)}>
              Aperçu
            </button>

            <LoadingButton
              type="submit"
              text="Modifier l'actualité"
              className="news-creation-submit-button primary-button"
              isLoading={isLoading}
            />
          </div>
        </form>

        {showPreview && (
          <Modal onClose={() => setShowPreview(false)}>
            <div className="news-creation-preview-modal">
              <h1>Aperçu de l'actualité</h1>
              <NewsDisplay 
              newsItem={formData}
              />
            </div>
          </Modal>
        )}
      </main>
      <Footer />
    </div>
  );
}

export default withAuthenticator(NewsCreationPage,  withAuthenticatorOptions);