import { useEffect, useState } from "react";

const Icon = ({ name }) => {
  const [svgContent, setSvgContent] = useState("<svg></svg>");

  useEffect(() => {
    fetch(`${process.env.PUBLIC_URL}/icons/${name}.svg`)
      .then((response) => response.text())
      .then((data) => setSvgContent(data));
  }, []);

  return <div className="svg-icon" dangerouslySetInnerHTML={{ __html: svgContent }} style={{
    padding: 0,
    margin: 0,
    display: 'flex',
    height: 'auto',
    width: 'auto',
  }}/>;
};

export default Icon;
