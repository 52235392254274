import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { FaMapMarkerAlt, FaClock, FaInfoCircle } from 'react-icons/fa';

import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import '../../main.css';
import './ContactPage.css';
import { NavLink } from 'react-router-dom';
import ContactInfo from '../../components/sections/ContactInfo';
import GoogleMap from '../../components/common/GoogleMap';
import Icon from '../../components/icons/Icon';
import Title from '../../components/layout/Title';

import ImportSection from '../../components/common/ImportSection';


const ContactPage = () => {
  //////////////////// VARIABLES ////////////////////
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [budget, setBudget] = useState('');
  const [company, setCompany] = useState('');
  const [message, setMessage] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsHighlight, setTermsHighlight] = useState(false);
  const [submitStatus, setSubmitStatus] = useState('Envoyer');

  /*//////////////////// METHODS ////////////////////*/

  // Form submission handler
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!termsAccepted) {
      setTermsHighlight(true);
      return;
    }

    const templateParams = {
      name,
      email,
      phone,
      budget,
      company,
      message,
    };

    // Modifier le texte du bouton pour indiquer l'envoi en cours
    setSubmitStatus('...');

    emailjs.send('service_jiq187f', 'template_y508rco', templateParams, 'Ge1LwLM71Yj1aFS_J')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setSubmitStatus('Envoyé');
        setName('');
        setEmail('');
        setPhone('');
        setBudget('');
        setCompany('');
        setMessage('');
        setTermsAccepted(false);
        setTermsHighlight(false);
        setTimeout(() => setSubmitStatus('Envoyer'), 3000); // Revenir à "Envoyer" après 3 secondes
      })
      .catch((error) => {
        console.error('FAILED...', error);
        setSubmitStatus('Erreur');
        setTimeout(() => setSubmitStatus('Envoyer'), 3000); // Revenir à "Envoyer" après 3 secondes
      });
  };

  return (

    // <div className="ContactPage bg-image" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/abstract-wooden-bg-03.svg)` }}>
    <div className="ContactPage bg-image" >
      <Header activePage="contact" helmetTitle="Drouaire - Contact" />

      <main className="contact-main">
        <div className="contact-container page">
          <section className="contact-coordinates-section">
            <Title title="Nos coordonnées" />

            <ContactInfo />

            <GoogleMap />

            <div className='contact-address-and-schedule'>
              <div className="contact-item contact-address">
                <div className="contact-header">
                  <Icon name="location_on" />
                  <h3>Adresse</h3>
                </div>
                <div className="contact-content">
                  <p>63 avenue du Maréchal Foch<br />BP83 - 77504 CHELLES</p>
                </div>
              </div>
              <div className="contact-item contact-schedule">
                <div className="contact-header">
                  <FaClock />
                  <h3>Horaires d'ouverture</h3>
                </div>
                  <div className="contact-content">
                    <p>Du lundi au vendredi : <br></br><strong>07h30 – 12h00</strong> et <strong>13h30 – 17h00</strong></p>
                  </div>
              </div>
            </div>

          </section>
          <section className="contact-form-section">
            <Title title="Nous contacter" />

            <form className="contact-form">
              <div className="form-columns">
                {/* Column 1 */}
                <div className="form-column">
                  <div className="form-group">
                    <label htmlFor="name">Prénom & Nom *</label>
                    <input
                      type="text"
                      id="name"
                      placeholder="Jean Dupont"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email *</label>
                    <input
                      type="email"
                      id="email"
                      placeholder="jean.dupont@gmail.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="phone">Téléphone *</label>
                    <input
                      type="phone"
                      id="phone"
                      placeholder="06 XX XX XX XX"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                  </div>
                </div>

                {/* Column 2 */}
                <div className="form-column">
                  <div className="form-group">
                    <label htmlFor="company">Entreprise</label>
                    <input
                      type="company"
                      id="company"
                      placeholder="..."
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="city">Ville *</label>
                    <input
                      type="city"
                      id="city"
                      placeholder="..."
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="message">Message *</label>
                    <textarea
                      id="message"
                      placeholder="Bonjour, ..."
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      required
                      autoComplete="new-message"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label>Importer ou déposer une pièce-jointe</label>
                <ImportSection
                  importText="Importer une pièce-jointe"
                  importType='multiple'
                  acceptedFileFormats={['.pdf', '.docx', '.jpg', '.png', '.jpeg']}
                />
              </div>
              {/* Single Row for Checkbox and Submit Button */}
              <div className="form-group form-group-checkbox">
                <input
                  type="checkbox"
                  id="terms"
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                  required
                />
                <label htmlFor="terms">
                  J'accepte les <span></span>
                  <NavLink to="/mentions-legales"><a href="#">Mentions légales </a></NavLink>
                  & la <NavLink to="/politique-de-confidentialite"><a href="#">Politique de confidentialité</a></NavLink>
                </label>
              </div>
              <div className="form-submit">
                <button type="submit" className="submit-button" onClick={handleSubmit}>{submitStatus}</button>
              </div>

            </form>
          </section>
        </div>
      </main>

      <Footer />
    </div>
  );
}

export default ContactPage;