import { useContext } from "react";
import { ConfigContext } from "../context/ConfigContext.js";

const useGetAllEstimation = () => {
  
  // Function to fetch all estimations for commercial purpose
  const getAllEstimation = async (idToken, accessToken, setCommercialEstimationList) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;

      const response = await fetch(`${apiUrl}/api/estimation/getAllEstimation`, {
        method: 'GET',
        headers: {
          'X-ID-Token': idToken,
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log(data);
      setCommercialEstimationList(data.estimationList);

    } catch (error) {
      console.error('Error during estimation list retrieval :', error);
    }
  };

  return {
    getAllEstimation,
  };

};

export default useGetAllEstimation;