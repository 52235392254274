import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import ReferencesSection from '../../components/sections/ReferencesSection';
import ServicesSection from '../../components/sections/ServicesSection';
import LoadingSpinner from '../../components/common/LoadingSpinner';
import '../../main.css';
import './ReferencePage.css';
import Title from '../../components/layout/Title';

const ReferencePage = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (location.hash) {
      const elementId = location.hash.replace('#', '');
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  const handleReferencesLoaded = () => {
    setIsLoading(false);
  };

  return (
    // <div className="ReferencePage bg-image" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/abstract-wooden-bg-03.svg)` }}>
    <div className="ReferencePage bg-image" >
      <Header activePage="realisations" helmetTitle="Drouaire - Réalisations" />
      <main>
        <section className="service-main-section">
          <Title title="Réalisations" />
          {isLoading && (
            <div className="loading-container">
              <LoadingSpinner size="4rem" />
            </div>
          )}
          <ReferencesSection isEditable={false} onReferencesLoaded={handleReferencesLoaded} />
        </section>
        {/* <section className="service-main-section">
          <ServicesSection />
        </section> */}
      </main>
      <Footer />
    </div>
  );
}

export default ReferencePage;