import React from 'react';
import './LoadingIndicator.css';
import LoadingBar from '../common/LoadingBar';

const LoadingIndicator = ({ loadingText="" }) => (
  <div className="loading-indicator-container">
    <div className="loading-logo-container">
      <img src={`${process.env.PUBLIC_URL}/logo-drouaire/logo-drouaire-Longue-Uni-Ivoire.svg?v=${new Date().getTime()}`} alt="Drouaire Logo" className="loading-logo" />
      <LoadingBar loadingText={loadingText} onDark={true} />
    </div>
  </div>
);

export default LoadingIndicator; 