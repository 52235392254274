import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { withAuthenticatorOptions } from '../auth/AuthComponent';

import { ConfigContext } from '../../context/ConfigContext.js';
import Header from '../../components/layout/Header.js';
import Footer from '../../components/layout/Footer.js';
import './CommercialEstimatePage.css';


const CommercialEstimatePage = () => {
    const location = useLocation();
    const commercialEstimation = location.state?.commercialEstimation;
    const navigate = useNavigate();
    const { userRole } = useContext(ConfigContext);

    // Redirect to /my-account if refreshed
    useEffect(() => {
        if (userRole !== 'commercial') {
            navigate('/mon-espace-client');
        }
    }, [userRole, navigate]);


    const calculateTotal = () => {
        return commercialEstimation.purchasePlan.reduce((total, item) => {
            return total + (item.quantity || 0) * (item.price || 0);
        }, 0);
    };

    const regimeDict = {
        "particulier": "Particulier",
        "charpentier": "Charpentier",
        "bureau_d_etude": "Bureau d'étude",
      }
      const getRegimeName = (regime) => {
        if (regimeDict[regime] === undefined) {
          return regime;
        }
        return regimeDict[regime];
      }

    return (
        // <div className="EstimatePage bg-image" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/abstract-wooden-bg-03.svg)` }}>
        <div className="EstimatePage bg-image" >
            <Header activePage="mon-espace-client" helmetTitle={`Drouaire - Devis ${commercialEstimation.id || ''}`} />
            <main className="commercial-estimate">
                <section className="estimate-section">
                    <div className="estimate-header">
                        <div className="my-account-estimate-link">
                            <a onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}>Devis des prospects</a>
                            <span> / {commercialEstimation.ownerInfo.givenName} {commercialEstimation.ownerInfo.familyName} / {commercialEstimation.estimationName}</span>
                        </div>
                    </div>
                    <div className="commercial-estimate-title-header">

                        <div className="estimate-owner-info">
                            <p className="owner-name estimate-owner-info-item">
                                {commercialEstimation.ownerInfo.givenName} {commercialEstimation.ownerInfo.familyName}
                            </p>
                            <p className="owner-regime estimate-owner-info-item">
                                Régime : {getRegimeName(commercialEstimation.ownerInfo.optimizationRegime)}
                            </p>
                            <p className="owner-tel estimate-owner-info-item">
                                <strong>Tel</strong> : {commercialEstimation.ownerInfo.telephone}
                            </p>
                            <p className="owner-email estimate-owner-info-item">
                                <strong>Email</strong> : {commercialEstimation.ownerInfo.email}
                            </p>
                        </div>

                    </div>
                    <div className="estimate-content">
                        <div className="estimate-table-container">
                            {commercialEstimation.purchasePlan.map((product) => (
                                <div className="estimate-product-container" >
                                    <div className="estimate-product-header">
                                        <p className="secondary-text-color">{product.quantity}x</p>
                                        <strong>{product.denomination}</strong>
                                    </div>
                                    <div className="estimate-quantity-price">
                                        <p className="secondary-text-color">{product.productSection}x{product.productLength}</p>
                                        <p>{product.price * product.quantity} €</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="estimate-footer">
                            <div className="estimate-total">
                                <div className="estimate-total-label">TOTAL</div>
                                <div className="estimate-total-amount">{calculateTotal()} €</div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
            <Footer />
        </div>
    );
};


export default withAuthenticator(CommercialEstimatePage, withAuthenticatorOptions);