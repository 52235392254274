import React, { useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import './EntreprisePage.css';
import './ValeursRsePage.css';
import RseSection from '../../components/sections/RseSection';
import Icon from '../../components/icons/Icon';
import Title from '../../components/layout/Title';

const EntreprisePage = () => {
  const location = useLocation();

  useEffect(() => {
    // Vérifie si nous avons un hash dans l'URL
    if (location.hash) {
      // Retire le # du hash pour obtenir l'id
      const id = location.hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        const elementPosition = element.getBoundingClientRect().top + window.scrollY; // Position de l'élément
        window.scrollTo({ top: elementPosition - 64, behavior: 'smooth' }); // Scroll à 64px au-dessus
      }
    } else {
      // Si pas de hash, scroll en haut de la page
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    // <div className="EntreprisePage bg-image" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/abstract-wooden-bg-03.svg)` }}>
    <div className="EntreprisePage bg-image" >
      <main>
        <Header activePage="l-entreprise" helmetTitle="Drouaire - L'entreprise" preloadImages={[`${process.env.PUBLIC_URL}/assets/camion-historique.webp`]} />

        <section className="history-section">
          <Title title="L'entreprise" />
          
          <div className="history-milestone">
            <div className="historic-text">
              <p>
                Jean-Luc DROUAIRE et Michel MAIRE de la scierie RHD PRODUCTIONS fondent Drouaire.
              </p>
              <p>
                L'entreprise s'est développée durant 30 ans jusqu'à devenir un leader de la distribution 
                des bois de charpente sur l'Ile de France.
              </p>
            
              <div>
                <Link to="/histoire" className="history-button">
                  Lire notre histoire
                </Link>
              </div>
            </div>
            <div className="historic-image">
              <img src={`${process.env.PUBLIC_URL}/assets/camion-historique.webp?v=${new Date().getTime()}`} alt="Camion historique Drouaire" />
            </div>
          </div>

        </section>

        <section className="stats-section">
          <Title title="Drouaire en quelques chiffres" />
          <div className="stats-grid">
            <div className="stat-item">
              {/* <Icon name="warehouse" /> */}
              <div className="stat-value">2</div>
              <div className="stat-label">dépôts en Ile de France</div>
            </div>
            <div className="stat-item">
              {/* <Icon name="group" /> */}
              <div className="stat-value">18</div>
              <div className="stat-label">collaborateurs</div>
            </div>
            <div className="stat-item">
              {/* <Icon name="trending_up" /> */}
              <div className="stat-value">11 M€</div>
              <div className="stat-label">de chiffre d'affaires en 2023</div>
            </div>
            <div className="stat-item">
              {/* <Icon name="box" /> */}
              <div className="stat-value">11 000 m²</div>
              <div className="stat-label">de surface de stockage</div>
            </div>
            <div className="stat-item">
              {/* <Icon name="forklift" /> */}
              <div className="stat-value">2 500</div>
              <div className="stat-label">références en stock</div>
            </div>
            <div className="stat-item">
              {/* <Icon name="truck" /> */}
              <div className="stat-value">7 000</div>
              <div className="stat-label">références distribuées par an</div>
            </div>
          </div>
        </section>

        {/* ------------------------------------------- new ------------------------------------------ */}

        <section id="valeurs" className="values-section">
          <Title title="Valeurs" />

          <img src={`${process.env.PUBLIC_URL}/assets/nos-valeurs.webp?v=${new Date().getTime()}`} alt="Nos valeurs illustration" className='nos-valeurs-image' />

          <div className="value-item">
            <div className="value-content">
              <h3>Engagement</h3>
              <p>
                Nous priorisons la satisfaction client grâce à un <strong> suivi personnalisé</strong>, 
                des<strong> produits de qualité</strong> et une <strong> implication totale</strong> de 
                nos équipes. Chez DROUAIRE, nous visons l'excellence avec des objectifs exigeants et cohérents.
              </p>
            </div>
          </div>

          <hr />

          <div className="value-item">
            <div className="value-content">
              <h3>Réactivité</h3>
              <p>
                De la disponibilité des produits à la livraison, nous assurons un service 
                <strong> rapide et efficace</strong> à chaque étape pour répondre aux attentes de nos clients.
              </p>
            </div>
          </div>

          <hr />

          <div className="value-item">
            <div className="value-content">
              <h3>Expertise métier & produits</h3>
              <p>
                « Le Bon Bois, au Bon Endroit » résume notre savoir-faire. Avec plus de 
                <strong> 20 ans d'expérience</strong>, nos équipes proposent des solutions 
                <strong> adaptées aux besoins</strong> les plus <strong> exigeants</strong>.
              </p>
            </div>
          </div>

          <hr />

          <div className="value-item">
            <div className="value-content">
              <h3>Indépendance</h3>
              <p>
                Grâce à notre <strong> liberté</strong> dans le choix des gammes et fournisseurs, 
                nous privilégions des partenaires alignés sur les <strong> attentes de nos clients 
                et nos valeurs de qualité et d'éthique</strong>.
              </p>
            </div>
          </div>

          <hr />

          <div className="value-item">
            <div className="value-content">
              <h3>Intégrité</h3>
              <p>
                Transparence, honnêteté et responsabilité sont au cœur de nos pratiques. 
                Nous croyons qu'une <strong> création de valeur durable</strong> repose sur un 
                <strong> comportement éthique</strong> et <strong> rigoureux</strong> envers nos 
                collaborateurs, clients, fournisseurs et partenaires.
              </p>
            </div>
          </div>
        </section>

        <section id="rse" className="rse-section">
          <div className="rse-content">            
            <RseSection />
            </div>
            <div className="rse-image-section">
                <img 
                src={`${process.env.PUBLIC_URL}/assets/entreprise-engagee.webp?v=${new Date().getTime()}`} 
                alt="Entreprise engagée, Charte d'engagements RSE de LCB" 
                />
                <a href="https://www.lecommercedubois.org/p/66/charte-dengagements-rse-de-lcb" className="rse-link">Charte d'engagements RSE de LCB</a>
            </div>
        </section>
      </main>
    <Footer />
    </div>
  );
};

export default EntreprisePage; 