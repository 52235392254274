import React from "react";
import "./LoadingButton.css";
import LoadingSpinner from "./LoadingSpinner";

const LoadingButton = ({ isLoading, text, children, onClick, disabled, className="", ...props }) => {
  return (
    <button
      className={`loading-button ${isLoading ? "loading" : ""} ${className}`}
      onClick={onClick}
      disabled={isLoading || disabled} // Désactivé pendant le chargement
      {...props}
    >
      {isLoading ? <LoadingSpinner onPrimary={className.includes("primary-button")} size="2rem"/> : children}
      <span className={`loading-button-text ${isLoading ? "loading" : ""}`}>{text}</span>
    </button>
  );
};

export default LoadingButton;
