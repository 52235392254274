import React, { useEffect } from 'react';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import Title from '../../components/layout/Title';
import './HistoirePage.css';

const HistoirePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="HistoirePage bg-image">
      <Header activePage="histoire" helmetTitle="Drouaire - Notre Histoire" />
      <main>
        <Title title="Notre Histoire" />
        
        <div className="timeline">
          <div className="timeline-item">
            <div className="year">1982</div>
            <div className="historic-year-content">
              <h3>La Fondation</h3>
              <p>Création de DROUAIRE BOIS PANNEAUX LAMBRIS par Jean-Luc DROUAIRE (agent commercial bois) et Michel MAIRE de la scierie Résineux du Haut-Doubs.</p>
              <p>Mission : Distribuer les produits de la scierie aux négoces et aux professionnels du bâtiment de la région parisienne.</p>
              <p>Le principal concurrent de DROUAIRE est l'entreprise familiale LARESCHE.</p>
              <img 
                src={`${process.env.PUBLIC_URL}/assets/camion-historique.webp?v=${new Date().getTime()}`} 
                alt="Camion historique Drouaire" 
                className="timeline-image"
              />
            </div>
          </div>

          <div className="timeline-item">
            <div className="year">1982-1995</div>
            <div className="historic-year-content">
              <h3>Période d'Expansion</h3>
              <p>Extension par Jean-Luc DROUAIRE du plan de vente aux différents produits bois et panneaux (notamment ceux de couverture).</p>
            </div>
          </div>

          <div className="timeline-item">
            <div className="year">2006</div>
            <div className="historic-year-content">
              <h3>Acquisition Stratégique</h3>
              <p>Rachat de la société Hohweiller, négoce de matériaux à Esbly.</p>
            </div>
          </div>

          <div className="timeline-item">
            <div className="year">2009</div>
            <div className="historic-year-content">
              <h3>Transition de Direction</h3>
              <p>Départ à la retraite de Jean-Luc DROUAIRE et reprise en intégralité de l'activité par la scierie RHD PRODUCTIONS.</p>
              <p>De son côté, François LARESCHE cède son entreprise familiale à un Groupe et œuvre durant 2 ans au développement de l'activité de ce dernier et d'un autre acteur du secteur.</p>
            </div>
          </div>

          <div className="timeline-item">
            <div className="year">2011</div>
            <div className="historic-year-content">
              <h3>Nouveau Départ</h3>
              <p>DROUAIRE souffre d'une importante dégradation financière, François LARESCHE et son associé Frédéric VALEUR s'investissent dans ce nouveau projet et acquièrent une partie du capital de DROUAIRE.</p>
              <p>Objectif : Se concentrer sur l'activité bois de structure, bardages et terrasses à destination des professionnels du bâtiment.</p>
            </div>
          </div>

          <div className="timeline-item">
            <div className="year">2012</div>
            <div className="historic-year-content">
              <h3>Renouveau</h3>
              <p>Année qui marque le renouveau de DROUAIRE comme plateforme de distribution performante à 'hauteur d'homme'. Les salariés sont formés techniquement aux produits et à leur environnement chantier ; la logistique est spécifiquement adaptée à ces produits.</p>
            </div>
          </div>

          <div className="timeline-item">
            <div className="year">2015</div>
            <div className="historic-year-content">
              <h3>Croissance et Consolidation</h3>
              <p>Croissance régulière de l'entreprise. Rachat complémentaire du capital par François LARESCHE et son associé ; la scierie restant néanmoins actionnaire au regard de son engagement historique.</p>
              <p>L'activité poursuit son développement.</p>
            </div>
          </div>

          <div className="timeline-item">
            <div className="year">2024</div>
            <div className="historic-year-content">
              <h3>Leader Régional</h3>
              <p>Départ en retraite de Frédéric Valeur ; François LARESCHE devient actionnaire principal de DROUAIRE.</p>
              <p>L'entreprise est depuis près d'une décennie, un acteur majeur dans la distribution de bois de structure en Ile de France auprès des professionnels du bâtiment, avec plus de 7000 références distribués par an et une équipe de 18 collaborateurs passionnés.</p>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default HistoirePage; 