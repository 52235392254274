import { useContext } from 'react';

import { ConfigContext } from '../context/ConfigContext.js';

const useDeleteEstimation = () => {
  const { idToken, accessToken, currentEstimation, setCurrentEstimation, estimationList, setEstimationList } = useContext(ConfigContext);
  
  // Function to delete a estimation
    const deleteEstimation = async (estimationId, setIsDeleting) => {
      
      try {
        setIsDeleting(true);
        const apiUrl = process.env.REACT_APP_API_URL;
        
        const response = await fetch(`${apiUrl}/api/estimation/deleteEstimation?estimationId=${estimationId}`, { // Updated URL to include estimationId as query parameter
          method: 'DELETE',
          headers: {
            'X-ID-Token': idToken,
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setEstimationList(estimationList.filter(estimation => estimation.estimationId !== data.estimationId));

        // Reset the current estimation
        setCurrentEstimation({estimationId: "currentEstimationId", estimationName: `Devis du ${new Date().toISOString().split('T')[0]}`, purchasePlan: [], lastModified: `${new Date().toISOString().split('T')[0]}`});
        
      } catch (error) {
        console.error('Erreur lors de la suppression du estimation :', error);
      }
      setIsDeleting(false);
    };
  

  return { deleteEstimation };
};

export default useDeleteEstimation;