import React, { useEffect, useState, useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { I18n } from 'aws-amplify/utils';
import { signOut } from 'aws-amplify/auth';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { translations } from '@aws-amplify/ui-react';
import { FaSortAmountDown, FaSortAmountUp, FaSort } from 'react-icons/fa';

import { ConfigContext } from '../../context/ConfigContext';
import useGetTokens from '../../hooks/useGetTokens';
import useGetUserRole from '../../hooks/useGetUserRole';
import useGetEstimationListByUserId from '../../hooks/useGetEstimationListByUserId';
import useGetAllEstimation from '../../hooks/useGetAllEstimation';
import useGetNews from '../../hooks/useGetNews';
import useGetReferences from '../../hooks/useGetReferences';
import LoadingIndicator from '../../components/sections/LoadingIndicator';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import NewsDisplay from '../../components/sections/NewsDisplay';
import ReferencesSection from '../../components/sections/ReferencesSection';
import '../../main.css';
import './MyAccountPage.css';
import './MyAccountLoginPage.css';
import '../auth/AuthComponent.css';
import '@aws-amplify/ui-react/styles.css';
import LoadingButton from '../../components/common/LoadingButton';
import LoadingBar from '../../components/common/LoadingBar';

import { withAuthenticatorOptions } from '../auth/AuthComponent';

I18n.putVocabularies(translations);
I18n.setLanguage('fr');

// Ajouter les traductions personnalisées en français et en espagnol
I18n.putVocabularies({
  fr: {
    'Sign In': 'Se connecter',
    'Sign Up': "S'inscrire",
    'Sign Out': 'Déconnexion',
    'Forgot your password?': 'Mot de passe oublié ?',
    'Reset your password': 'Réinitialisez votre mot de passe',
    'Send Code': 'Envoyer le code',
    'Back to Sign In': 'Retour à la connexion',
    'Username': "Nom d'utilisateur",
    'Password': 'Mot de passe',
    'Email': 'Adresse email',
    'Phone Number': 'Numéro de téléphone',
    'Confirm': 'Confirmer',
    'Create Account': 'Créer un compte',
    'Enter your Username': "Entrez votre nom d'utilisateur",
    'Enter your password': 'Entrez votre mot de passe',
    'Enter your email': 'Entrez votre adresse email',
    'Enter your phone number': 'Entrez votre numéro de téléphone',
    'User does not exist.': 'L\'utilisateur n\'existe pas.',
    'Invalid phone number format.': 'Format de numéro de téléphone invalide.',
    "Auth UserPool not configured.": "Erreur de serveur interne. (UserPool non configuré)"
  }
});


const regimeDict = {
  "particulier": "Particulier",
  "charpentier": "Charpentier",
  "bureau_d_etude": "Bureau d'étude",
}
const getRegimeName = (regime) => {
  if (regimeDict[regime] === undefined) {
    return regime;
  }
  return regimeDict[regime];
}

const MyAccountPage = () => {
  const [sortDirection, setSortDirection] = useState('desc');
  const [sortColumn, setSortColumn] = useState('Date'); // Initialisation de la colonne par défaut
  const [sortCommercialDirection, setSortCommercialDirection] = useState('desc');
  const { userRole, setUserRole, estimationList, setEstimationList, setCurrentEstimation, news } = useContext(ConfigContext);
  const [isSigningOut, setIsSigningOut] = useState(false);
  const [areEstimatesLoading, setAreEstimatesLoading] = useState(true);
  const [commercialEstimationList, setCommercialEstimationList] = useState([]);

  const { getTokens } = useGetTokens();
  const { getUserRole } = useGetUserRole();
  const { getEstimationListByUserId } = useGetEstimationListByUserId();
  const { getNews } = useGetNews();
  const { getReferences } = useGetReferences();
  const { getAllEstimation } = useGetAllEstimation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setAreEstimatesLoading(true);
      const { idToken, accessToken, statusCode } = await getTokens();
      if (statusCode === "200") {
        const userRoleResponse = await getUserRole(idToken, accessToken);
        if (userRoleResponse === "user") {
          await getEstimationListByUserId(idToken, accessToken);
        } else if (userRoleResponse === "admin") {
          getNews();
          getReferences();
        }
        else if (userRoleResponse === "commercial") {
          await getAllEstimation(idToken, accessToken, setCommercialEstimationList);
        }
      }
      if (statusCode === "500") {
        navigate('/mon-espace-client');
      }
      setAreEstimatesLoading(false);
    };
    fetchData();
  }, []);


  const calculateTotal = (estimate) => {
    return estimate.purchasePlan.reduce((total, item) => {
      return total + (item.quantity || 0) * (item.price || 0);
    }, 0);
  };

  const handleCommercialSort = (column) => {
    const newDirection = sortColumn === column && sortCommercialDirection === 'desc' ? 'asc' : 'desc';
    setSortCommercialDirection(newDirection);
    setSortColumn(column);

    const sortedEstimates = [...commercialEstimationList].sort((a, b) => {
      let aValue, bValue;

      // Définir les valeurs pour le tri
      if (column === 'Date') {
        aValue = new Date(a.lastModifiedDate);
        bValue = new Date(b.lastModifiedDate);
      } else if (column === 'Nom') {
        aValue = `${a.ownerInfo.givenName} ${a.ownerInfo.familyName}`;
        bValue = `${b.ownerInfo.givenName} ${b.ownerInfo.familyName}`;
      } else if (column === 'Régime') {
        aValue = getRegimeName(a.ownerInfo.optimizationRegime);
        bValue = getRegimeName(b.ownerInfo.optimizationRegime);
      } else if (column === 'Prix') {
        aValue = calculateTotal(a);
        bValue = calculateTotal(b);
      }

      // Comparer les valeurs en fonction de la direction
      if (newDirection === 'asc') {
        return aValue > bValue ? 1 : -1;
      }
      return aValue < bValue ? 1 : -1;
    });

    setCommercialEstimationList(sortedEstimates);
  };

  const handleSort = () => {
    const newDirection = sortDirection === 'desc' ? 'asc' : 'desc';
    setSortDirection(newDirection);

    const sortedEstimates = [...estimationList].sort((a, b) => {
      if (newDirection === 'desc') {
        return new Date(b.lastModifiedDate) - new Date(a.lastModifiedDate);
      }
      return new Date(a.lastModifiedDate) - new Date(b.lastModifiedDate);
    });

    setEstimationList(sortedEstimates);
  };

  const handleEditEstimate = (estimate) => {
    setCurrentEstimation(estimate);
    navigate("/mon-espace-client/devis");
  };

  const handleCreateNewEstimation = () => {
    setCurrentEstimation({
      estimationId: "currentEstimationId",
      estimationName: `Devis du ${new Date().toISOString().split('T')[0]}`,
      purchasePlan: [],
      lastModified: `${new Date().toISOString().split('T')[0]}`
    });
    navigate("/produits");
  }

  const handleGetCommercialEstimate = (commercialEstimation) => {
    console.log("commercialEstimation given", commercialEstimation);
    navigate("/mon-espace-client/devis-commercial", {
      state: { commercialEstimation: commercialEstimation }
    });
  };

  const handleSignOut = () => {
    setIsSigningOut(true);
    console.log("Signing out...");
    signOut()
      .then(() => {
        navigate('/mon-espace-client');
        setIsSigningOut(false);
        setUserRole("not_authenticated");
      })
      .catch((error) => {
        console.error("Erreur lors de la déconnexion:", error);
        setIsSigningOut(false);
      });
  };

  return (
    // <div className="MyAccountPage bg-image" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/abstract-wooden-bg-03.svg)` }}>
    <div className="MyAccountPage bg-image" >
      <Header activePage="mon-espace-client" helmetTitle="Drouaire - Mon Espace Client" />
      <main>
        {userRole === "guest" && (
          <LoadingIndicator loadingText="Connexion en cours..." />
        )}

        {userRole === "admin" && (
          <section className="my-account-section">
            <h1>Bienvenue sur votre espace administrateur.</h1>
            <p>Ici, vous pouvez gérer l'actualité et les réalisations de votre site web.</p>
          </section>
        )}
        {userRole === "commercial" && (
          <section className="my-account-section">
            <h1>Bienvenue sur votre espace commercial.</h1>
            <p>Vous trouverez ci-dessous la liste des devis édités par vos éventuels clients.</p>
          </section>
        )}
        {userRole === "user" && (
          <section className="my-account-section">
            <h1>Bienvenue sur votre espace client.</h1>
            <p>Vous trouverez ci-dessous la liste de vos devis que vous pouvez modifier, télécharger ou supprimer.</p>
          </section>
        )}

        {userRole === "admin" && (
          <section className="my-account-section">

            <div className="my-account-admin-subsection">
              <h2><strong>Gestion de l'actualité</strong></h2>
              <NewsDisplay newsItem={news} />
              <div className="my-account-admin-buttons">
                <button onClick={() => navigate('/modification-d-actualite')}>
                  Modifier l'actualité
                </button>
              </div>
            </div>

            <div className="my-account-admin-subsection">
              <h2><strong>Gestion des réalisations</strong></h2>
              <ReferencesSection isEditable={true} />
              <div className="my-account-admin-buttons">
                <button onClick={() => navigate('/creation-de-reference')}>
                  Ajouter une réalisation
                </button>
              </div>
            </div>
          </section>
        )}

        {userRole === "commercial" && (
          <section className="my-account-section">
            <h2>Mes devis</h2>
            <hr></hr>
            <div className="my-account-estimates-table-container">
              <table className="my-account-estimates-table">
                <thead>
                  <tr>
                    <th onClick={() => handleCommercialSort('Date')}>
                      <div className="my-account-date-header">
                        Date
                        {sortColumn === 'Date' ? (
                          sortCommercialDirection === 'desc' ? <FaSortAmountDown className="my-account-sort-icon" /> : <FaSortAmountUp className="my-account-sort-icon" />
                        ) : (
                          <FaSort className="my-account-sort-icon inactive-sort-icon" />
                        )}
                      </div>
                    </th>
                    <th onClick={() => handleCommercialSort('Nom')}>
                      <div className="my-account-date-header">
                        Nom
                        {sortColumn === 'Nom' ? (
                          sortCommercialDirection === 'desc' ? <FaSortAmountDown className="my-account-sort-icon" /> : <FaSortAmountUp className="my-account-sort-icon" />
                        ) : (
                          <FaSort className="my-account-sort-icon inactive-sort-icon" />
                        )}
                      </div>
                    </th>
                    <th onClick={() => handleCommercialSort('Régime')}>
                      <div className="my-account-date-header">
                        Régime
                        {sortColumn === 'Régime' ? (
                          sortCommercialDirection === 'desc' ? <FaSortAmountDown className="my-account-sort-icon" /> : <FaSortAmountUp className="my-account-sort-icon" />
                        ) : (
                          <FaSort className="my-account-sort-icon inactive-sort-icon" />
                        )}
                      </div>
                    </th>
                    <th onClick={() => handleCommercialSort('Prix')}>
                      <div className="my-account-date-header">
                        Montant
                        {sortColumn === 'Prix' ? (
                          sortCommercialDirection === 'desc' ? (
                            <FaSortAmountDown className="my-account-sort-icon" />
                          ) : (
                            <FaSortAmountUp className="my-account-sort-icon" />
                          )
                        ) : (
                          <FaSort className="my-account-sort-icon inactive-sort-icon" />
                        )}
                      </div>
                    </th>
                  </tr>
                </thead>


                <tbody>
                  {areEstimatesLoading && commercialEstimationList.length === 0 && (
                    <tr>
                      <td colSpan="3"><LoadingBar loadingText="Chargement des devis..." /></td>
                    </tr>
                  )}
                  {!areEstimatesLoading && commercialEstimationList.length === 0 &&
                    <tr>
                      <td colSpan="3">Aucun devis disponible.</td>
                    </tr>
                  }
                  {commercialEstimationList.map((estimate, index) => (
                    <tr key={"commercial-estimate-" + index} className={index % 2 === 0 ? "even-row" : "odd-row"} onClick={() => handleGetCommercialEstimate(estimate)}>
                      <td>{new Date(estimate.lastModifiedDate).toLocaleDateString('fr-FR')}</td>
                      <td>
                        {estimate.ownerInfo.givenName} {estimate.ownerInfo.familyName}
                      </td>
                      <td>
                        {getRegimeName(estimate.ownerInfo.optimizationRegime)}
                      </td>
                      <td key={`commercial-total-${index}`}>
                        {calculateTotal(estimate) + ' €'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>
        )}

        {userRole === "user" && (
          <section className="my-account-section">
            <h2>Mes devis</h2>
            <hr></hr>
            <div className="my-account-estimates-table-container">
              <table className="my-account-estimates-table">
                <thead>
                  <tr>
                    <th>
                      <div className="my-account-date-header" onClick={handleSort}>
                        Date
                        {sortDirection === 'desc' ?
                          <FaSortAmountDown className="my-account-sort-icon" /> :
                          <FaSortAmountUp className="my-account-sort-icon" />
                        }
                      </div>
                    </th>
                    <th>Nom</th>
                    <th>Prix</th>
                  </tr>
                </thead>
                <tbody>
                  {areEstimatesLoading && estimationList.length === 0 && (
                    <tr>
                      <td colSpan="3"><LoadingBar loadingText="Chargement des devis..." /></td>
                    </tr>
                  )}
                  {!areEstimatesLoading && estimationList.length === 0 &&
                    <tr>
                      <td colSpan="3">Aucun devis disponible.</td>
                    </tr>
                  }
                  {estimationList.map((estimate, index) => (
                    <tr key={"estimate-" + index} onClick={() => handleEditEstimate(estimate)}>
                      <td>{new Date(estimate.lastModifiedDate).toLocaleDateString('fr-FR')}</td>
                      <td>
                        {estimate.estimationName}

                      </td>
                      <td key={`total-${estimate.id}`}>
                        {calculateTotal(estimate) + ' €'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className='myaccount-create-new-estimate'>
              <button className="myaccount-create-new-estimate-btn" onClick={handleCreateNewEstimation}>Créer un nouveau devis</button>
            </div>
          </section>
        )}

        {(userRole === "user" || userRole === "admin" || userRole === "commercial") && (
          <section className="my-account-logout-section">
            <LoadingButton isLoading={isSigningOut} className="secondary-button" onClick={() => handleSignOut()} text="Se déconnecter"></LoadingButton>
          </section>
        )}
      </main>
      <Footer />
    </div>
  );
}



// export default MyAccountPage;
export default withAuthenticator(MyAccountPage,  withAuthenticatorOptions);