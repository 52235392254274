import React from 'react';
import { useNavigate} from 'react-router-dom';
import './NotFoundPage.css';

const NotFoundPage = () => {
    const navigate = useNavigate();
    return (
        <div className="not-found-container">
            <h1>404</h1>
            {/* <div className='wave'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0099ff" fill-opacity="1" d="M0,32L40,58.7C80,85,160,139,240,170.7C320,203,400,213,480,208C560,203,640,181,720,154.7C800,128,880,96,960,96C1040,96,1120,128,1200,144C1280,160,1360,160,1400,160L1440,160L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path></svg>

            </div> */}
            <div class="ocean">
                <h2>La page n'existe pas</h2>
                <button className="home-link" onClick={() => navigate('/')}>Revenir sur le site</button>
            </div>
        </div >
    );
};

export default NotFoundPage; 