import React from 'react';
import '../../main.css';
import './LegalPage.css';
import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';


const PrivacyPolicyPage = () => {
  return (
    <div className="PrivacyPolicyPage">
      <Header activePage="privacy-policy" helmetTitle = "DivinData - Politique de Confidentialité"/>
      <main>
        
        <section className="privacy-policy-section page">
    
            <h2>Politique de Confidentialité</h2>
            <p>La présente Politique de Confidentialité explique comment nous, DROUAIRE BOIS PANNEAUX LAMBRIS, collectons, utilisons, et protégeons les données personnelles que vous : <ul><li>Nous fournissez des informations via notre formulaire de contact sur le site <strong>www.drouaire-bois.com</strong>.</li><li>Nous fournissez des informations lors de la création et l'utilisation de votre compte client sur le site <strong>www.drouaire-bois.com</strong>.</li></ul></p>

            <h3>1. Données collectées</h3>
            <p><strong>1.1. Données collectées via le formulaire de contact</strong></p>
            <p>Lors de l’envoi d’une demande via notre formulaire, nous recueillons les informations suivantes :</p>
            <ul>
                <li>Prénom et nom</li>
                <li>Adresse email</li>
                <li>Numéro de téléphone</li>
                <li>Nom de l’entreprise</li>
                <li>Ville</li>
                <li>Message libre</li>
            </ul>
            <p>Ces informations sont nécessaires pour répondre à votre demande de manière précise et personnalisée.</p>
            <p><strong>1.2. Données collectées lors de la création de compte client</strong></p>
            <p>Lors de la création de votre compte client, nous recueillons les informations suivantes :</p>
            <ul>
                <li>Prénom et nom</li>
                <li>Adresse email</li>
                <li>Régime d'optimisation</li>
                {/* <li>Adresse de livraison</li> */}
                {/* <li>Numéro de téléphone (optionnel)</li> */}
                {/* <li>Nom de l’entreprise (optionnel)</li> */}
            </ul>
            <p>Lors de l'utilisation de votre compte client, nous pouvons être amenés à recueillir les informations suivantes :</p>
            <ul>
                <li>Adresse de livraison</li>
                <li>Adresse de facturation</li>
                <li>Coordonnées de l'entreprise</li>
            </ul>
            
            <h3>2. Finalité de la collecte</h3>
            <p>Les données recueillies sont uniquement utilisées dans le cadre du traitement de vos demandes et de notre relation commerciale. Elles ne sont ni vendues, ni partagées avec des tiers sans votre consentement, sauf obligation légale.</p>
            
            <h3>3. Conservation des données</h3>
            <p>Vos données personnelles sont conservées uniquement pendant la durée nécessaire au traitement de votre demande. Passé ce délai, elles seront supprimées de nos systèmes de manière sécurisée.</p>

            <h3>4. Sécurité des données</h3>
            <p>Nous mettons en place les mesures techniques et organisationnelles nécessaires pour garantir la sécurité et la confidentialité de vos données personnelles et prévenir tout accès non autorisé.</p>

            <h3>5. Droits des utilisateurs</h3>
            <p>Conformément à la réglementation en vigueur (RGPD), vous disposez d’un droit d’accès, de rectification, et de suppression de vos données personnelles. Vous pouvez exercer ces droits en nous contactant à l’adresse : <strong>contact@drouaire-bois.com</strong>.</p>
            
            <h3>6. Acceptation de la Politique de Confidentialité</h3>
            <p>En cochant la case "J'accepte les Mentions légales & la Politique des données personnelles" et en cliquant sur "Envoyer" ou "Créer mon compte", vous consentez à ce que vos données soient collectées et utilisées selon les termes de cette Politique de Confidentialité.</p>
            
            <h3>Contact</h3>
            <p>Vous pouvez nous contacter par les moyens suivants :</p>
            <ul>
                <li><strong>Téléphone :</strong> 01 60 08 06 18</li>
                <li><strong>Email :</strong> contact@drouaire-bois.com</li>
            </ul>
        
        </section>

      </main>
      <Footer />
      
    </div>
  );
}

export default PrivacyPolicyPage;