import React, { useEffect } from 'react';

import Header from '../../components/layout/Header';
import Footer from '../../components/layout/Footer';
import ProductsSection from '../../components/sections/ProductsSection';
import ServicesSection from '../../components/sections/ServicesSection';
import NewsDisplay from '../../components/sections/NewsDisplay';
import '../../main.css';
import './HomePage.css';
import '../ProductPage/ProductPage.css';

const HomePage = () => {

  useEffect(() => {
    // Vérifie si nous avons un hash dans l'URL
    if (location.hash) {
      // Retire le # du hash pour obtenir l'id
      const id = location.hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        const elementPosition = element.getBoundingClientRect().top + window.scrollY; // Position de l'élément
        window.scrollTo({ top: elementPosition - 64, behavior: 'smooth' }); // Scroll à 64px au-dessus
      }
    } else {
      // Si pas de hash, scroll en haut de la page
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <div className="HomePage bg-image">
      <Header activePage="accueil" helmetTitle="Drouaire"/>
      <main>
        <div className="home-news-section dark">
          <div className="home-news-section-content">
            <NewsDisplay />
          </div>
        </div>

        <section className="home-title-section">
          <h1>Le spécialiste Bois, Panneaux, Lambris en Ile de France</h1>
          <h2>pour les professionnels de la construction et de la rénovation</h2>
        </section>

        <section className="home-product-section">
          <ProductsSection />
        </section>

        <section id="services" className="home-services-section">
          <ServicesSection />
        </section>

        {/* <section className="home-contact-section">
          <Title title="Contactez-nous" />
          <ContactInfo />
        </section> */}

        {/* <section className="home-faq-section">
        </section> */}

      </main>
      <Footer />
    </div>
  );
}

export default HomePage;
