import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import GoogleMap from '../common/GoogleMap';
import Newsletter from '../common/Newsletter';
import './Footer.css';

const Footer = () => {

    return (
        <footer className="footer2 dark">
            <div className="footer2-content">
                {/* Première colonne - Coordonnées */}
                <div className="footer2-column">
                    <img src={`${process.env.PUBLIC_URL}/logo-drouaire/logo-drouaire-Longue-Uni-Ivoire.svg?v=${new Date().getTime()}`} alt="Drouaire logo" className="footer2-logo" />
                    <p><i>63 avenue du Maréchal Foch</i></p>
                    <p><i>BP83 - 77504 CHELLES</i></p>
                    <div><a className="footer2-email" href='mailto:contact@drouaire-bois.com'>contact@drouaire-bois.com</a></div>
                    <div><a className="footer2-tel" href='tel:+33160080618'>Tel. +33(0)1 60 08 06 18</a></div>

                    <NavLink to="/contact"><button className="footer-button footer-contact-button" >Nous contacter</button></NavLink>

                    <div className="footer-contact-schedule">
                        <p>Du lundi au vendredi : <br></br><strong>07h30 – 12h00</strong> et <strong>13h30 – 17h00</strong></p>
                    </div>
                </div>

                {/* Deuxième colonne - Navigation */}
                <div className="footer2-column">
                    <nav className="footer2-nav">
                        <NavLink to="/produits">PRODUITS</NavLink>
                        <NavLink to="/#services">SERVICES</NavLink>
                        <NavLink to="/realisations">RÉALISATIONS</NavLink>
                        <NavLink to="/l-entreprise">L'ENTREPRISE</NavLink>
                        <NavLink to="/l-entreprise#valeurs">VALEURS</NavLink>
                        <NavLink to="/l-entreprise#rse">ENGAGEMENT RSE</NavLink>
                        <NavLink to="/mon-espace-client"><span className='footer-nav-espace-client'>ESPACE CLIENT</span></NavLink>
                        <NavLink to="/mentions-legales">Mentions légales</NavLink>
                        <NavLink to="/politique-de-confidentialite">Politique de confidentialité</NavLink>
                    </nav>
                </div>

                {/* Troisième colonne - Newsletter */}
                <div className="footer2-column">
                    <Newsletter />
                    <hr></hr>
                    <div className="map-container">
                        <GoogleMap />
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
