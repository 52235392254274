import { useContext } from 'react';

import { ConfigContext } from '../context/ConfigContext';

const useGetUserRole = () => {
  const { setUserRole } = useContext(ConfigContext);

  const getUserRole = async (idToken, accessToken) => {

    try {
      const apiUrl = process.env.REACT_APP_API_URL;

      const response = await fetch(`${apiUrl}/api/user/getUserRole`, {
        method: 'GET',
        headers: {
          'X-ID-Token': idToken,
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setUserRole(data.userRole);
      
      return data.userRole;
    } catch (error) {
      console.error('Error during user role retrieval :', error);
    }
  };

  return {
    getUserRole
  };
};

export default useGetUserRole;