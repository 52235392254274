import { useContext } from 'react';

import { ConfigContext } from '../context/ConfigContext.js';

const useGetEstimationListByUserId = () => {
  const { setEstimationList } = useContext(ConfigContext);
 
  // Function to fetch chat history from the backend
  const getEstimationListByUserId = async (idToken, accessToken) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;

      const response = await fetch(`${apiUrl}/api/estimation/getEstimationListByUserId`, {
        method: 'GET',
        headers: {
          'X-ID-Token': idToken,
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setEstimationList(data.estimationList);
      
    } catch (error) {
      console.error('Error during estimation list retrieval :', error);
    }
  };

  return {
    getEstimationListByUserId,
  };

};

export default useGetEstimationListByUserId;