import { useContext } from 'react';

import { ConfigContext } from '../context/ConfigContext';

const useUploadFileToS3 = () => {
    const { idToken, accessToken } = useContext(ConfigContext);
    const apiUrl = process.env.REACT_APP_API_URL;

    const getPresignedUrl = async (file) => {
        if (file) {
            try {

                const response = await fetch(`${apiUrl}/api/media/generatePresignedUrl`, {
                    method: 'POST',
                    headers: {
                        'X-ID-Token': idToken,
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({fileName: file.name})
                });

                if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
                const { presignedPostUrl, presignedPostFields } = await response.json();
                return { presignedPostUrl, presignedPostFields };

            } catch (error) {
                console.error('Error during presigned URL generation:', error);
                return null
            }
        }
    };
    
    // Helper function to upload the file to S3
    const uploadFileToS3 = async (file) => {
        try {
            
            const { presignedPostUrl, presignedPostFields } = await getPresignedUrl(file);
            // Construct the form data
            const formData = new FormData();
            Object.entries(presignedPostFields).forEach(([key, value]) => {
                formData.append(key, value);
            });
            formData.append('file', file);
        
            // Upload the file to S3
            const uploadResponse = await fetch(presignedPostUrl, {
                method: 'POST',
                body: formData
            });
        
            if (!uploadResponse.ok) throw new Error(`Error uploading file: ${uploadResponse.status}`);
            //console.log('File uploaded successfully!');
            
            const s3Key = presignedPostUrl + presignedPostFields['key'];
            return s3Key;
        } catch (error) {
            console.error('Error uploading file to S3:', error);
        }
    };

    return { uploadFileToS3 };
};

export default useUploadFileToS3;