import { useContext } from 'react';

import { ConfigContext } from '../context/ConfigContext.js';

const useSaveReference = () => {
  const { idToken, accessToken, setReferences } = useContext(ConfigContext);
  
  // Function to fetch chat history from the backend
  const saveReference = async ( referenceId, referenceName, referenceLocation, period, surface, description, stakeHolders, products, s3Keys, setIsLoading) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;

      const response = await fetch(`${apiUrl}/api/reference/saveReference`, {
        method: 'POST',
        headers: {
          'X-ID-Token': idToken,
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          referenceId: referenceId, 
          referenceName: referenceName,
          referenceLocation: referenceLocation,
          period: period,
          surface: surface,
          description: description,
          stakeHolders: stakeHolders,
          products: products,
          photos: s3Keys
         }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      console.log('Reference saved');
      setIsLoading(false);
      
    } catch (error) {
      console.error('Error during references list retrieval :', error);
    }
  };

  return { saveReference };
};

export default useSaveReference;